import React, { Component } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink as NavLink1,
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { setUser } from "action/index.js";
import { connect } from "react-redux";
import { thead, tbody } from "variables/general";
import { store } from "store/index";
import { Helmet } from "react-helmet";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 0,
      all_ok1: true,
      all_ok: true,
      count: [100, 100, 100, 100, 100],
      currentSection: "Speaking Section",
      subscriptionId:
        store.getState().user.active_subscription &&
        store.getState().user.active_subscription.length > 0
          ? store.getState().user.active_subscription[0].stripe_subscription_id
          : null,
    };
  }
  componentDidMount() {
    let isChrome =
      /Chrome/.test(navigator.userAgent) &&
      !/Edg/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor) &&
      !/Chromium/.test(navigator.userAgent);
    let all_ok1 = isChrome ? true : false;
    if (navigator.getUserMedia === undefined) {
      this.setState({ all_ok: false, all_ok1: false });
    } else {
      navigator.getUserMedia(
        { audio: true },
        () => {
          console.log("Permission Granted");
          this.setState({ all_ok1: all_ok1 });
        },
        () => {
          this.setState({ all_ok1: false });
          console.log("Permission Denied");
        }
      );
    }

    API({
      method: "GET",
      url: "mock1",
    })
      .then((data) => {
        console.log(data);
        this.toggle(1);
        this.props.setUser(data.data.user);
        let count = [100, 100, 100, 100, 100];
        //if (this.state.subscriptionId) {
        const details = data.data.user.user_details;
        count = [
          details.speak_ct,
          details.write_ct,
          details.read_ct,
          details.listen_ct,
          details.full_ct,
        ];
        //}

        this.setState({ data: data.data.result, count: count });
      })
      .catch(() => {});
  }

  toggle = (tab) => {
    let all_ok = true;
    if (this.state.activeTab !== tab) {
      let currentSection = "";
      switch (tab) {
        case 1:
          //currentSection = "Speaking Section";
          break;
        case 2:
          //currentSection = "Writing Section";
          break;
        case 3:
          //currentSection = "Reading Section";
          break;
        case 4:
          //currentSection = "Listening Section";
          break;
        case 5:
          // code block
          break;
        default:
        // code block
      }
      this.setState(
        { activeTab: tab, currentSection: currentSection },
        function () {
          if ([1, 5].includes(this.state.activeTab)) {
            all_ok = this.state.all_ok1;
          } else {
            all_ok = true;
          }
          this.setState({ all_ok: all_ok });
        }
      );
    }
  };

  render() {
    let i = 0;
    return (
      <>
        <Helmet>
          <title>20task, Empowering your Institute.</title>
        </Helmet>
        {true && (
          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Mock Test</CardTitle>
                    {false && this.state.activeTab !== 5 && (
                      <Link
                        to={
                          "/admin/progress/" + this.state.activeTab + "?mock=1"
                        }
                      >
                        <Button>
                          Score Analysis of {this.state.currentSection}
                        </Button>
                      </Link>
                    )}
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                        tabs
                      >
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 1 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(1);
                            }}
                          >
                            Speaking
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 2 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(2);
                            }}
                          >
                            Writing
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 3 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(3);
                            }}
                          >
                            Reading
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 4 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(4);
                            }}
                          >
                            Listening
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 5 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(5);
                            }}
                          >
                            Full Mock Test
                          </NavLink1>
                        </NavItem>
                      </Nav>
                    </div>
                    {!this.state.all_ok && (
                      <div className="mt-5">
                        <ChromeCheck />
                      </div>
                    )}
                    {this.state.all_ok && (
                      <Table className="mock-open">
                        <thead className="text-primary">
                          <tr>
                            <th className="">Title</th>
                            <th className="">Description</th>
                            <th className="">Time</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((prop, key) => {
                            if (prop.category.id == this.state.activeTab) {
                              if (
                                i < this.state.count[this.state.activeTab - 1]
                              ) {
                                i++;
                                return (
                                  <tr key={key}>
                                    <td className="">{prop.title}</td>
                                    <td className="">{prop.description}</td>
                                    <td className="">{prop.time}</td>
                                    <td className="">
                                      {prop.category.id !== 5 && (
                                        <a
                                          href={"/admin/test/" + prop.id + "/0"}
                                          className="nav-link"
                                          activeClassName="active"
                                        >
                                          <i className="fa fa-link" />
                                          <p>Take Test</p>
                                        </a>
                                      )}
                                      {prop.category.id === 5 && (
                                        <a
                                          href={
                                            "/admin/fulltest/" + prop.id + "/0"
                                          }
                                          className="nav-link"
                                          activeClassName="active"
                                        >
                                          <i className="" />
                                          <p>Take Full Test</p>
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr>
                                    <td className="">{prop.title}</td>
                                    <td className="">{prop.description}</td>
                                    <td className="">{prop.time}</td>
                                    <td className="">
                                      <Link to="/mock-plan">
                                        Subscribe to mock plan
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              }
                            }
                          })}
                        </tbody>
                      </Table>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
