import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import API from "api.js";
import FameWall from '../components/Frontend/FameWall';
import Packages from '../components/Frontend/Packages';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import VideoTestimonial from '../components/Frontend/VideoPTETestimonial';
import cookie from 'react-cookies'
import axios from 'axios';
import "components/custom.css";
import { Redirect, NavLink } from "react-router-dom";
class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error:null,
      data:[],
    };
  }
  componentDidMount() {
    
    API({
        method: 'GET',
        url: 'package'
      }).then(data => {
        console.log(data)
        this.setState({'data':data.data.data});
    }).catch(()=>{
      
    });
  }
  componentWillUnmount() {
    
  }

  

  render() {
    if (this.state.login) {
        return (<Redirect to="/admin/dashboard" />);
    }
    return (
    <React.Fragment>
      {/*<div className="subsBanner">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" xs="12">
              <div className="bannerContent">
                <h2>Excel in pte with this video course<span>Complete preparation package to achieve the score you desire</span></h2>
                <a href="" className="downArrow">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>*/}
      <div className="whyCourse">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" sm={6}>
              <h2>Why Online Video Course?</h2>
              <ul>
                <li>24 hours access.</li>
                <li>All the proven tips, templates and strategies.</li>
                <li>Real exam practice questions.</li>
                <li>Complete access to online practice portal.</li>
                <li>3 full length scored mock text with score-report and feedback.</li>
                <li>One to one consultation session with the tutor. (30 minutes each)</li>
                <li>Regular prediction files.</li>
                <li>Access to exam prediction files with highly repeated questions.</li>
              </ul>
            </Col>
            <Col className="ml-auto mr-auto" sm={6}>
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/EJSkM7j9UWw"></iframe>
            </Col>
          </Row>
        </Container>
      </div>
      <Packages />
      <FameWall />
        <VideoTestimonial /> 
        <VideoTuts />
        <section className="refundPolicy">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="refundNote text-center">
                            <h4>Refund Policy</h4>
                            <p>We don't offer any refund in any given circumstances. Nevertheless, a refund is possible is in case you are charged extra due to a technical problem. The refund request should be initiated within 24 hours by sending an email with all the details on <a hrf="mailto:info@20task.com">info@20task.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Subscription;
