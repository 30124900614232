import React from 'react';
import {store,persistor} from 'store/index';
import { Link } from 'react-router-dom';
function Footer(props) {
	return (
        <React.Fragment>
		<section className="footer-sec py-60" id="footer-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-xs-12">
            <img className="main-logo footer-col-logo" loading="lazy" src="/images/20task/images/logo.png" width="120" height="80" lazy="true" alt="20Task"/>
            <p>Empowering your Institute</p>
          </div>
          <div className="col-lg-3 col-md-3 col-xs-12">
            <h3 className="footer-col-title">Quick Links</h3>
            <ul className="quick-links-ul">
              <li><a href="/"> Home</a></li>
              <li><a href="#">PTE Mock Test</a></li>
              <li><a href="#">Pricing</a></li>
              <li><a href="#">FAQs</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-xs-12">
            <h3 className="footer-col-title">PTE Modules</h3>
            <ul className="pte-know-ul">
              <li><a href="/speaking">Speaking</a></li>
              <li><a href="/writing">Writing</a></li>
              <li><a href="/reading">Reading</a></li>
              <li><a href="/listening">Listening</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-xs-12">
            <h3 className="footer-col-title">Connect With Us</h3>
            <ul className="connect-with-us-ul">
              <li>
                <ul className='double-number-class'>
                  <li>
                    <span>
                      <a href="tel:918360986507">
                        <img src="/images/20task/images/IN.png"/>&nbsp;&nbsp;&nbsp;+918360986507
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <a href="tel:918360829427">
                        <img src="/images/20task/images/IN.png"/>&nbsp;&nbsp;&nbsp;+918360829427
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
              <li><a href="mailto:info@20task.com">Email: info@20task.com</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
        </React.Fragment>
	);
}

export default Footer;