import React,{Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import API from "api.js";

import { tbody } from "variables/general";

class MockResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      arr:{1 : [1,2,3,4,5],2 :[6,7],3 :[8,9,10,11,12],4: [13,14,15,16,17,18,19,20]},
      group:[],
      remarks:[],
      activeTab:1,
      f:true
    };
  }
   componentDidMount() {
    API({
        method: 'GET',
        url: 'mock/resultDetail/'+this.props.match.params.id
      }).then(data => {
        let group = data.data.data.question.reduce((r, a) => {
           r[a.type] = [...r[a.type] || [], a];
           return r;
          }, {});
        this.setState({group:group,data:data.data.data.question,remarks:data.data.remarks});
    }).catch(()=>{
      
    });

  }

  getQuestionType = (type) =>{
    let title = "";
    switch(type) {
      case 1:
        title = "Read Aloud";
        break;
      case 2:
        title = "Repeat Sentence"
        break;
      case 3:
        title = "Describe Image";
        break;
      case 4:
        title = "Re-tell Lecture";
        break;
      case 5:
        title = "Answer Short Questions";
        break;
      case 6:
        title = "Summarize written text";
        
        break;
      case 7:
        title = "Write Essay";
        
        break;
      case 8:
        title = "Multiple Type, Single Answer ";
        break;
      case 9:
        title = "Multiple Type, Double Answer ";
        break;
      case 10:
        title = "Reorder Paragraph ";
        break;
      case 11:
        title = "Reading Fill in the Blanks ";
        break;
      case 12:
        title = "Fill in the Blanks Reading & Writing ";
        break;
      case 13:
        title = "Summarize Spoken text ";
        break;
      case 14:
        title = "MCQ Single answer ";
        break;
      case 15:
        title = "MCQ multiple answer ";
        break;
      case 16:
        title = "Listening Fill In the Blanks ";
        break;
      case 17:
        title = "Highlight correct summary ";
        break;
      case 18:
        title = "Select Missing Word ";
        break;
      case 19:
        title = "Highlight Incorrect word ";
        break;
      case 20:
        title = "Write from Dictation ";
        
        break;
      default:
        // code block
    }
    return title;
  }

  calculateScore = (arr) => {
    let score = 0;
    let from = 0;
    arr.map((v)=>{
        v.score.map((val)=>{
          score += val.score;
          from += val.from;
        });
    });
    let per = score*100/from;
    return per.toFixed(2);
  }
  calculateRemarks = (arr) => {
    let content_sc = 0;
    let content_fr = 0;
    let overall_sc = 0;
    let overall_fr = 0;
    let pro_sc = 0;
    let pro_fr = 0;
    let score = 0;
    let fluency_sc = 0;
    let fluency_fr = 0;
    let grammar_sc = 0;
    let grammar_fr = 0;
    let vocab_sc = 0;
    let vocab_fr = 0;
    let form_sc = 0;
    let form_fr = 0;
    let spelling_sc = 0;
    let spelling_fr = 0;
    let struct_sc = 0;
    let struct_fr = 0;
    let ling_sc = 0;
    let ling_fr = 0;
    let from = 0;
    let type = ""; 
    arr.map((v)=>{
        type = v.type;
        v.score.map((val)=>{
          if (val.type ===1) {
            fluency_sc += val.score;
            fluency_fr += val.from;
          }
          if (val.type ===0) {
            content_sc += val.score;
            content_fr += val.from;
          }
          if (val.type ===2) {
            pro_sc += val.score;
            pro_fr += val.from;
          }
          if (val.type ===3) {
            overall_sc += val.score;
            overall_fr += val.from;
          }if (val.type ===4) {
            grammar_sc += val.score;
            grammar_fr += val.from;
          }if (val.type ===5) {
            form_sc += val.score;
            form_fr += val.from;
          }if (val.type ===6) {
            vocab_sc += val.score;
            vocab_fr += val.from;
          }if (val.type ===7) {
            spelling_sc += val.score;
            spelling_fr += val.from;
          }if (val.type ===8) {
            struct_sc += val.score;
            struct_fr += val.from;
          }if (val.type ===9) {
            ling_sc += val.score;
            ling_fr += val.from;
          }
          score += val.score;
          from += val.from;
        });
    });
    let newArr = [(content_sc*100)/content_fr, (fluency_sc*100)/fluency_fr,(pro_sc*100)/pro_fr,(overall_sc*100)/overall_fr, (grammar_sc*100)/grammar_fr,(form_sc*100)/form_fr,(vocab_sc*100)/vocab_fr ,(spelling_sc*100)/spelling_fr, (struct_sc*100)/struct_fr, (ling_sc*100)/ling_fr ]
    //console.error(newArr)
    let remarkAr ="";
    newArr.map((v,k)=>{
      if (!isNaN(v)) {
        let mapArr = this.getScoreType(v,type,k);
        remarkAr += ' '+(mapArr.length >0)?mapArr[0].remarks+'. ':"l";  
      }
      
    });
    console.error(remarkAr);
    //let per = score*100/from;
    //let remarksArr = this.state.remarks.filter((a)=>{
      //return (a.min < per) && (a.max >= per) && a.type == type ;
    //});
    //console.log(remarksArr)

    //return (remarksArr.length == 0)?"":remarksArr[0].remarks +' fluency :'+(fluency_sc*100)/fluency_fr+' content :'+(content_sc*100)/content_fr+' pro :'+(pro_sc*100)/pro_fr+' overall :'+(overall_sc*100)/overall_fr;
    return remarkAr;
  }

  getScoreType =(per,type,scType)=>{
    return this.state.remarks.filter((a)=>{
      return (a.min <= per) && (a.max >= per) && a.type == type && a.score_type == scType;
    });
  }

  getTime = (arr) => {
    let time = 0;
    arr.map((v)=>{
        time += v.q_time;
    });
    return (time > 59)?`${Math.floor(time/60)} minutes ${Math.floor(time%60)} sec`:`${time} sec`;
  }

  getUnattempted = (arr) => {
    let c = 0;
    arr.map((v)=>{
      if (v.not_attempted == 0) {
          c += 1;
      }
        
    });
    return c;
  }

  toggleCorrectSection = () => {
    let tab = 1;
    if(Object.keys(this.state.group).filter(x => [1,2,3,4,5].includes(parseInt(x))).length > 0){
        tab = 1; 
    }else if(Object.keys(this.state.group).filter(x => [6,7].includes(parseInt(x))).length){
        tab = 2;
    }else if(Object.keys(this.state.group).filter(x => [8,9,10,11,12].includes(parseInt(x))).length){
      tab = 3;
    }else{
      tab = 4;
    }
    this.setState({activeTab:tab,f:false});
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }
 
  render() {
    if (this.state.data.length > 0 && this.state.f) {
      this.toggleCorrectSection();  
    }
    
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Mock Test Feedback</CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
      
    </div>
                  <Table >
                    <tbody>
                      {Object.keys(this.state.group).map((val, key) => {
                        if (true) {
                          return (
                          <tr key={key}>
                                  <td className="">
                                    <h4><b>{this.getQuestionType(parseInt(val))}</b></h4>
                                    <b>Question Attempted -</b> {this.state.group[val].length}
                                    <br/><b>Score percentage -</b> {this.calculateScore(this.state.group[val])} %
                                    <br/><b>Remarks -</b>{this.calculateRemarks(this.state.group[val])}
                                  </td>
                                  
                          </tr>
                        );  
                        }
                        
                      })}
                    </tbody>
                  </Table>
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default MockResult;
