//import Dashboard from "views/Dashboard.jsx";
import Dashboard from "views/NewDashboard";
import LockScreen from "views/pages/LockScreen.jsx";
import Video from "views/Video.jsx";
import Zoom from "views/Zoom.jsx";
import Subscription from "views/Subscription.jsx";
import PaymentForm from "views/PaymentForm.jsx";
import SubSuccess from "views/SubSuccess.jsx";
import material from "views/Material.jsx";
import prediction from "views/Prediction.jsx";
import Practice from "views/Practice.jsx";
import DailyFeedback from "views/DailyFeedback.jsx";
import DailyTask from "views/DailyTask.jsx";
import session from "views/Session.jsx";
import notification from "views/Notification.jsx";
import DailyTaskAdmin from "views/DailyTaskAdmin.jsx";
import QuestionList from "views/QuestionList.jsx";
import QuestionListWeekly from "views/QuestionListWeekly.jsx";
import Test from "views/Test.jsx";
import FullTest from "views/FullTest.jsx";
import ViewPdf from "views/ViewPdf.jsx";
import Result from "views/ResultNew";
import Score from "views/Score.jsx";
import ShowVideo from "views/ShowVideo.jsx";
import Mock from "views/Mock.jsx";
import PracticedDetail from "views/PracticedDetail.jsx";
import Template from "views/Template.jsx";
import Help from "views/Help.jsx";
import MockPending from "views/MockPending.jsx";
import MockResult from "views/MockResult.jsx";
import MockAnalysis from "views/MockAnalysis.jsx";
import MockAnalysis1 from "views/MockAnalysis1.jsx";
import Profile from "views/Profile.jsx";
import Transaction from "views/Transaction.jsx";
import Login from "views/pages/Login.jsx";
import LoginPortal from "views/pages/LoginPortal.jsx";
import ResetPassword from "views/pages/ResetPassword.jsx";
import Forgot from "views/pages/Forgot.jsx";
import Register from "views/pages/Register.jsx";
import Chrome from "views/Chrome.jsx";
import ChromeCheckGlobal from "views/ChromeCheckGlobal.jsx";
import Password from "views/Password.jsx";
import ProgressTracker from "views/ProgressTracker.jsx";
import NotFound from './page/NotFound';
import NotFoundAdmin from './page/NotFoundAdmin';
import CheckPost from "views/pages/CheckPost";
const routes = [
  {
    path: "/check/post",
    name: "Check Post",
    icon: "fa fa-question-mark",
    type: 2,
    component: CheckPost,
    layout: "/default"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: Dashboard,
    layout: "/admin",
    type:1,
  },{
    path: "/chrome",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: Chrome,
    layout: "/admin",
    type:2,
  },{
    path: "/progress/:id",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: ProgressTracker,
    layout: "/admin",
    type:2,
  },{
    path: "/allCheck",
    name: "Chrome Speech",
    icon: "fa fa-user",
    component: ChromeCheckGlobal,
    layout: "/admin",
    type:2,
  },{
    path: "/practice-detail",
    name: "Practiced Detail",
    icon: "fa fa-user",
    component: PracticedDetail,
    layout: "/admin",
    type:2,
  },{
    path: "/profile",
    name: "My Profile",
    icon: "fa fa-address-book-o",
    component: Profile,
    layout: "/admin",
    type:2,
  },
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    type:2,
    views: [
      
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
      },
      
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/forgot",
        name: "Forgot",
        mini: "R",
        component: Forgot,
        layout: "/auth"
      },
      {
        path: "/ResetPassword",
        name: "ResetPassword",
        mini: "R",
        component: ResetPassword,
        layout: "/auth"
      }
    ]
  },
  {
    path: "/test-material",
    name: "Practice Materials",
    icon: "fa fa-search",
    component: material,
    layout: "/admin",
    type:1
  },
  {
    path: "/weekly-prediction",
    name: "Weekly Prediction",
    icon: "fa fa-hourglass-half",
    component: prediction,
    layout: "/admin",
    type:1
  },
  {
    path: "/template",
    name: "Template",
    icon: "fa fa-clipboard",
    component: Template,
    layout: "/admin",
    type:1
  },
  {
    path: "/test/:id/:pending",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Test,
    layout: "/admin",
    type:2
  },
  {
    path: "/fulltest/:id/:pending",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: FullTest,
    layout: "/admin",
    type:2
  },
  {
    path: "/viewpdf/:id",
    name: "ViewPdf",
    icon: "nc-icon nc-bank",
    component: ViewPdf,
    layout: "/admin",
    type:2
  },
  {
    path: "/result/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Result,
    layout: "/admin",
    type:2
  },
  {
    path: "/scorecard/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: Score,
    layout: "/admin",
    type:2
  },{
    path: "/analysis/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: MockAnalysis,
    layout: "/admin",
    type:2
  },{
    path: "/mock-analysis/:id",
    name: "abc",
    icon: "nc-icon nc-bank",
    component: MockAnalysis1,
    layout: "/admin",
    type:2
  },
  {
    path: "/video/:id",
    name: "video",
    icon: "nc-icon nc-bank",
    component: ShowVideo,
    layout: "/admin",
    type:2
  },
  {
    path: "/practice/:type/:id/:index/:aType/:mark/:attempted/:complexity",
    name: "practice",
    icon: "nc-icon nc-bank",
    component: Practice,
    layout: "/admin",
    type:2
  },
  {
    path: "/questionList/:type/:id",
    name: "Question List",
    icon: "nc-icon nc-bank",
    component: QuestionList,
    layout: "/admin",
    type:2
  },{
    path: "/questions-weekly/:type/:id",
    name: "Question Weekly",
    icon: "nc-icon nc-bank",
    component: QuestionListWeekly,
    layout: "/admin",
    type:2
  },
  {
    path: "/mock",
    name: "Mock Test",
    icon: "fa fa-pencil-square-o",
    component: Mock,
    layout: "/admin",
    type:1
  },
  {
    path: "/pending",
    name: "Pending Mock Test",
    icon: "fa fa-clock-o",
    component: MockPending,
    layout: "/admin",
    type:1
  },
  {
    path: "/mock-result",
    name: "Mock Result Analysis",
    icon: "fa fa-bar-chart",
    component: MockResult,
    layout: "/admin",
    type:1
  },
  {
    path: "/daily-task",
    name: "Daily Task",
    icon: "fa fa-refresh",
    component: DailyTask,
    layout: "/admin",
    type:1
  },{
    path: "/success-plan",
    name: "Success Plan",
    icon: "fa fa-file-powerpoint-o",
    component: DailyTaskAdmin,
    layout: "/admin",
    type:1
  },
  {
    path: "/daily-feedback",
    name: "Daily Feedback",
    icon: "fa fa-calendar-check-o",
    component: DailyFeedback,
    layout: "/admin",
    type:1
  },{
    path: "/help",
    name: "Help",
    icon: "fa fa-youtube-play",
    component: Help,
    layout: "/admin",
    type:2
  },{
    path: "/zoom",
    name: "Zoom Meeting",
    icon: "fa fa-youtube-play",
    component: Zoom,
    layout: "/admin",
    type:2  
	},
  {
    path: "/subscription",
    name: "Videos",
    icon: "fa fa-youtube-play",
    component: Subscription,
    layout: "/sub",
    type:2
  },
  {
    path: "/payment/form/:id",
    name: "PaymentForm",
    icon: "fa fa-credit-card",
    component: PaymentForm,
    layout: "/sub",
    type:2
  },
  {
    path: "/success",
    name: "SubSuccess",
    icon: "fa fa-signal",
    component: SubSuccess,
    layout: "/sub",
    type:2
  },
  {
    path: "/password",
    name: "Change Password",
    icon: "fa fa-signal",
    component: Password,
    layout: "/admin",
    type:2
  },{
    path: "",
    name: "",
    icon: "fa fa-user",
    component: NotFoundAdmin,
    layout: "/admin",
    type:2,
  },{
        path: "",
        name: "Login",
        mini: "L",
        type:2,
        component: NotFound,
        layout: "/auth"
      }
  
];

export default routes;
