import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import API from "api.js";
import { displayResult } from "util/display-result";
import "components/custom.css";
import Unauthorized from "components/Exceptions/Unauthorized";
import ReportQuestion from "components/result-new/ReportQuestion";
import RemarksPopup from "components/result-new/RemarksPopup";
import Questions from "components/result-new/Questions";

const ResultNew = () => {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [mockData, setMockData] = useState({
    mockTitle: "",
    questionData: [],
    type: null,
    currentQuestion: 0,
    result: {},
    pending: false,
  });
  const [reportData, setReportData] = useState({
    reportText: "",
    reportErr: null,
    reportModal: false,
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("sidebar-mini");
    getData();

    return () => {
      document.body.classList.toggle("sidebar-mini");
    };
  }, []);

  useEffect(() => {
    displayResult(mockData);
  }, [mockData]);

  const getData = () => {
    API({
      method: "GET",
      url: "mock/resultDetail/" + params.id,
    })
      .then(({ data }) => {
        setIsLoaded(true);
        if (
          typeof data.message !== "undefined" &&
          typeof data.message !== undefined &&
          data.message === "unauthorized access"
        ) {
          setUnauthorized(true);
        }

        const dataMock = data.data;

        setMockData((prev) => ({
          ...prev,
          mockTitle: dataMock.mock.title,
          questionData: dataMock.question,
          type: dataMock.question[0].question.subcategory_id,
        }));
      })
      .catch(() => {});
  };

  const toggle = () => {
    setModal((prev) => !prev);
  };

  return (
    <>
      {isLoaded && (
        <>
          {!unauthorized ? (
            <>
              <ReportQuestion
                mockData={mockData}
                reportData={reportData}
                setReportData={setReportData}
              />
              {modal && (
                <RemarksPopup
                  mockData={mockData}
                  modal={modal}
                  toggle={toggle}
                />
              )}
              <div className="content questionsArea">
                <Questions
                  mockData={mockData}
                  setMockData={setMockData}
                  toggle={toggle}
                  setReportData={setReportData}
                />
              </div>
            </>
          ) : (
            <Unauthorized />
          )}
        </>
      )}
    </>
  );
};

export default ResultNew;
