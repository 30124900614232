import React from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { displayResult } from "util/display-result";
import ResultContainer from "./ResultContainer";

const Questions = ({ mockData, setMockData, toggle, setReportData }) => {
  const nextButton = () => {
    let question = mockData.questionData[mockData.currentQuestion + 1];
    setMockData((prev) => ({
      ...prev,
      result: {},
      currentQuestion: mockData.currentQuestion + 1,
      type: question.question.subcategory_id,
    }));
    displayResult(mockData);
  };

  const prevButton = () => {
    let question = mockData.questionData[mockData.currentQuestion - 1];
    setMockData((prev) => ({
      ...prev,
      result: {},
      currentQuestion: mockData.currentQuestion - 1,
      type: question.question.subcategory_id,
    }));
    displayResult(mockData);
  };

  const reportModalfunc = () => {
    setReportData((prev) => ({
      ...prev,
      reportModal: !prev.reportModal,
    }));
  };

  return (
    <Row>
      <Col xs={12}>
        <Card>
          {!mockData.pending && (
            <CardBody>
              <ResultContainer
                mockData={mockData}
                toggle={toggle}
                setMockData={setMockData}
                prevButton={prevButton}
                nextButton={nextButton}
              />
            </CardBody>
          )}
          {mockData.pending && (
            <CardBody>
              <div className="scoreContent text-center">
                <div className="scoreLoading">
                  <img src="https://gifimage.net/wp-content/uploads/2017/08/loading-gif-transparent-4.gif" />
                </div>
                <div className="scoreInfo">
                  <h4>AI Scoring Ongoing</h4>
                  <p>
                    Takes around 5 - 10 minutes
                    <br />
                    You can check back later
                  </p>
                </div>
              </div>
            </CardBody>
          )}
        </Card>
      </Col>

      {!mockData.pending && (
        <Col xs={12}>
          <div className="">
            {mockData.questionData.length != mockData.currentQuestion + 1 &&
              mockData.questionData.length !== 0 && (
                <Button
                  className="float-right"
                  color="primary"
                  onClick={nextButton}
                  size="lg"
                >
                  NEXT
                </Button>
              )}
            {mockData.currentQuestion !== 0 &&
              mockData.questionData.length !== 0 && (
                <Button
                  className="float-right"
                  color="primary"
                  onClick={prevButton}
                  size="lg"
                >
                  PREVIOUS
                </Button>
              )}
            {mockData.questionData.length !== 0 && (
              <Button
                color="info"
                className="bottom-btn"
                onClick={reportModalfunc}
                disabled={false}
                size="sm"
              >
                Report Question
              </Button>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Questions;
