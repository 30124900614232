import React from 'react';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,Button,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent, TabPane, Nav, NavItem,CardText,NavLink as NavLink1
} from "reactstrap";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import API,{base} from "api.js";
import 'react-daypicker/lib/DayPicker.css';
import DayPicker from 'react-daypicker';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          group:[],
          mock:{},
          planModal:false,
          result:false,
          remarks:[],
          activeTab:1,
          user:false,
          f:true,
          date:new Date(),
          isDate:false,
          selectedDate:"",
          dateArr:[],
          dateSelect:'',
          isTask:true,
          rec_link:"",
          rec_pwd:""
        
        };
      }
    componentDidMount() {
        API({
            method: 'GET',
            url: 'check-notifications',
            headers:{
              'timezone':Intl.DateTimeFormat().resolvedOptions().timeZone
            }
          }).then(data => {
            console.log(data)
            this.setState({data:data.data});
        }).catch(()=>{
        });
    }

    markNotificationRead = (notification_id) =>{
        var bodyFormData = new FormData();
        bodyFormData.set('notification_id', notification_id);
        API({
            method: 'POST',
            url: 'mark-notifications',
            data:bodyFormData,
            headers:{
              'timezone':Intl.DateTimeFormat().resolvedOptions().timeZone
            }
          }).then(data => {
            console.log(data)
            window.location.reload();
        }).catch(()=>{
        });
    }   

    render(){   
        return (
            <React.Fragment>
                <Helmet htmlAttributes>
                    <title>20task, Empowering your Institute.</title>
                </Helmet>
                <div className="content">
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Notification(s)</CardTitle>
                                    <i class="fa fa-bell" aria-hidden="true"> {this.state.data.length}</i>
                                </CardHeader>
                                <CardBody> 
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            {this.state.data.map((val,key)=>{
                                return (
                                    <div key={key}>
                                    <div className="notification_title"><p><b>{val.data.notification_type}</b></p><Button className="btn-icon btn-round" color="default" onClick={()=>{this.markNotificationRead(val.id);}}><i class="fa fa-eye" aria-hidden="true"></i></Button></div>
                                    <p>{val.data.notification_message}. Link - <a href={val.data.notification_link} target='_/blank'>{val.data.notification_link}</a>
                                    <br></br><span>Created At : {new Date(val.created_at+' UTC').toString()}</span>
                                    </p>
                                    <hr></hr>
                                </div>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;