import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Nav, NavItem,
  NavLink as NavLink1
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import API from "api.js";
import { Helmet } from "react-helmet";

import { thead, tbody } from "variables/general";

class MockPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab:0,
      all_ok:true,
      all_ok1:true
    };
  }
   componentDidMount() {
    
      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
      let all_ok1 = (isChrome)?true:false;
      if (navigator.getUserMedia === undefined) {
        this.setState({all_ok:false,all_ok1:false});
      }else{
        navigator.getUserMedia({ audio: true },
          () => {                   
            console.log('Permission Granted');
            this.setState({all_ok1:all_ok1}); 
          },
          () => {
            this.setState({all_ok1:false});
            console.log('Permission Denied');
            
          },
        );  
      }

      API({
          method: 'GET',
          url: 'pending/mock'
        }).then(data => {
          this.toggle(1);
          console.log(data)
          this.setState({'data':data.data});
      }).catch(()=>{
          
        });

  }

  toggle = tab => {
    let all_ok = true;
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab},function(){
        if ([1,5].includes(this.state.activeTab)) {
         all_ok = this.state.all_ok1;
        }else{
          all_ok = true;
        }
        this.setState({all_ok:all_ok});
      });
    }
  }
 
  render() {

    return (
      <>
      <Helmet>
        <title>20task, Empowering your Institute.</title>
        </Helmet>
      {true && <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Pending Mock Test</CardTitle>
                </CardHeader>
                <CardBody>
                <div>
      <Nav className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist" tabs>
        <NavItem>
          <NavLink1
            className={(this.state.activeTab === 1)?'active':''}
            onClick={() => { this.toggle(1); }}
          >
            Speaking
          </NavLink1>
        </NavItem>
        <NavItem>
          <NavLink1
            className={(this.state.activeTab === 2)?'active':''}
            onClick={() => { this.toggle(2); }}
          >
            Writing
          </NavLink1>
        </NavItem>
        <NavItem>
          <NavLink1
            className={(this.state.activeTab === 3)?'active':''}
            onClick={() => { this.toggle(3); }}
          >
            Reading
          </NavLink1>
        </NavItem>
        <NavItem>
          <NavLink1
            className={(this.state.activeTab === 4)?'active':''}
            onClick={() => { this.toggle(4); }}
          >
            Listening
          </NavLink1>
        </NavItem>
        <NavItem>
          <NavLink1
            className={(this.state.activeTab === 5)?'active':''}
            onClick={() => { this.toggle(5); }}
          >
            Full Mock Test
          </NavLink1>
        </NavItem>
      </Nav>
    </div>
                  {!this.state.all_ok && <div className="mt-5"><ChromeCheck /></div>}
                  {this.state.all_ok && <Table className="mock-open">
                                      <thead className="text-primary">
                                        <tr>
                                          
                                                <th className="">
                                                  Title
                                                </th>
                                                <th className="">
                                                  Description
                                                </th>
                                                <th className="">
                                                  Time
                                                </th>
                                                <th className="">
                                                  Action
                                                </th>
                                              
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.data.map((val, key) => {
                                          if(val?.mock?.category?.id == this.state.activeTab){
                                          return (
                                            <tr key={key}>
                                                    <td className="">
                                                      {val?.mock?.title}
                                                    </td>
                                                    <td className="">
                                                      {val?.description}
                                                    </td>
                                                    <td className="">
                                                      {val?.mock?.time}
                                                    </td>
                                                    <td className="">
                                                      {val.mock.category.id !== 5 && <a href={'/admin/test/'+val.mock_id+'/1'} className="nav-link" activeClassName="active" ><i className="fa fa-link" /> <p>Resume Test</p> </a>}
                                                      {val.mock.category.id === 5 && <a href={'/admin/fulltest/'+val.mock_id+'/1'} className="nav-link" activeClassName="active" ><i className="fa fa-link" /> <p>Resume Test</p> </a>}
                                        
                                                    </td>
                                                  
                                            </tr>
                                          );
                                        }
                                        })}
                                      </tbody>
                                    </Table>}
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>}
      </>
    );
  }
}

export default MockPending;
