import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Row,
  Progress,
  Button
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import Sound from 'react-sound';
import getBlobDuration from 'get-blob-duration';
import {base,s3base} from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class DescribeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:25,
      recordCount:0,
      recordId:1,
      intervalId:0,
      duration:0,
      questionId:"",
      reset:false,
      trans:'',
      correctFlag:true,
      soundStatus:false,
      file:'',
      imageLoaded:true
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
  	 //var intervalId = setInterval(this.timer, 1000);
	   //this.setState({intervalId: intervalId});
  	}
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			
  			return {recordCount:0,isRecording:false,secondCount:25,questionId: props.data.id,blobURL:'',reset:true,imageLoaded:false,trans:'',duration:0};
  		}else{
  			if (!state.questionId) {
  				return {questionId: props.data.id,imageLoaded:false};
  			}
  		}
  		
  		}
  	    
    
  }


  start = () => {
    this.setState({ soundStatus:true});
  };

  handleSongFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' })
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));
    }
  }

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a})  
    }
    
  }

  getDuration = async (blob) => {
    let duration = 0;
    duration = await getBlobDuration(blob).then((d) => {
      return d;
    }).catch(async (e) => {
      return await getBlobDuration(URL.createObjectURL(blob));
    });
    return duration;
  }

  getFile = (blob, buffer) => {
    let file = null;
    try {
      file = new File([blob], `noname1${this.state.questionId}.mp3`, { type: blob.type });
    } catch (err) {
      file = new File(buffer, `noname1${this.state.questionId}.mp3`, {
        type: blob.type,
        lastModified: Date.now()
      });
    }
    return file;
  }

  getFileOne = (blob, buffer) => {
    let file = null;
    try {
      file = new File([blob], 'noname1.mp3', { type: blob.type });
    } catch (err) {
      file = new File(buffer, 'noname1.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
    }
    return file;
  }

  getBlobURL = (blob, file) => {
    let url = null;
    try {
      url = URL.createObjectURL(file);
    } catch (err) {
      url = URL.createObjectURL(blob);
    }
    return url;
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          
          const duration = await this.getDuration(blob);
          const file = this.getFile(blob, buffer);
          const blobURL = this.getBlobURL(blob, file);

          SpeechRecognition.stopListening();
          
          this.setState({
            blobURL,
            isRecording: false,
            recordCount: 100,
            file
          });
          this.props.submit({
            data: file,
            audio_text: this.state.trans,
            duration
          }, true);
          this.props.showResult();

          //this.props.submit({'data':file },true)
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then( async ([buffer, blob]) => {

          const duration = await this.getDuration(blob);
          const file = this.getFileOne(blob, buffer);
          const blobURL = this.getBlobURL(blob, file);

          SpeechRecognition.stopListening();
          //this.props.submit({'data':file },true)
          //this.props.showResult();
          this.props.nextQuestion({'data': file,'audio_text':this.state.trans, duration:duration });
        }).catch((e) => console.log(e));
    }else{
      this.props.nextQuestion({'data': this.state.file,'audio_text':this.state.trans, 'duration': this.state.duration });
      //this.props.submit({'data': '' },true);
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'', 'audio_text':'','duration':this.state.duration});
     this.setState({correctFlag:false})
  }
  skip = () => {
    clearInterval(this.state.intervalId);
    this.start();
    var recordId = setInterval(this.timerRecord, 1000);
    this.setState({recordId: recordId,secondCount:0});
  };
	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			this.start();
			var recordId = setInterval(this.timerRecord, 1000);
			this.setState({recordId: recordId});
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}



	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  handleImageLoaded =() =>{
    this.setState({imageLoaded:true});
  }
  
  render() {
  	let question ="";
    if (!this.state.imageLoaded) {
      const img = new Image();
      img.src = s3base + this.props.data.media_link;
      if (img.complete) {
        var intervalId = setInterval(this.timer, 1000);
        this.setState({intervalId: intervalId,reset:false,imageLoaded:true});
          
      }
      
    }
  	if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
    if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		/*var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false});*/
  	}
    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }
    
    return (
      <>
      <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <Col xs={12}>
        <Row>
          <Col lg={6}>
            {!this.state.imageLoaded && <div style={{margin:'145px'}}>Loading...</div>}
            {this.state.imageLoaded && this.props.data && <img alt="media-link" width="500" onLoad={this.handleImageLoaded} src={s3base + this.props.data.media_link} />}
            
          </Col>
        	<div className="col-lg-6 p-0 text-center">
      		 { !this.props.resultMode && <div className="audio-box">
              <h4>Recorded Answer</h4>
              <p><strong>Current status:</strong></p>
              <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": 'Recording starts in '+this.state.secondCount+ ' seconds' } </p>
              {!this.props.testMode && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
              <hr></hr>
              {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
              {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
              <hr></hr>
              <Progress color="info" value={(this.state.recordCount*2.5)} />
              <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
            </div> }
  		    </div>
        </Row>
        <Col xs={12}>
          { this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
        </Col>
		    
      </Col> 
      <Col xs={12}><h5>{question}</h5></Col>
      {!this.props.resultMode && this.state.soundStatus && <Sound
                  url={s3base + "/ptedata/ptemedia/beep.mp3"}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                />}    
      </>
    );
  }
}
export default DescribeImage;