import React from "react";
import ReadAloud from "components/Question/Speaking/ReadAloud.jsx";
import DescribeImage from "components/Question/Speaking/DescribeImage.jsx";
import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
import RetellLec from "components/Question/Speaking/RetellLec.jsx";
import ShortQue from "components/Question/Speaking/ShortQue.jsx";
import Summary from "components/Question/Writting/Summary.jsx";
import Essay from "components/Question/Writting/Essay.jsx";
import SingleAnswer from "components/Question/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Reading/ReadingBlank.jsx";
import WrittingBlank from "components/Question/Reading/WrittingBlank.jsx";
import Summer from "components/Question/Listening/Summer.jsx";
import McqSingle from "components/Question/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Listening/CorrectSummary.jsx";
import Dictation from "components/Question/Listening/Dictation.jsx";
import Highlight from "components/Question/Listening/Highlight.jsx";
import { Row } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import { displayResult } from "util/display-result";
import Question from "./Question";
import PaginationBtns from "./PaginationBtns";

const ResultContainer = ({
  mockData,
  toggle,
  setMockData,
  nextButton,
  prevButton,
}) => {
  let que;
  let val;
  let topbar = false;
  let props = {};

  if (mockData.questionData) {
    props = {
      submit: () => {},
      showResult: () => {},
      data: mockData.questionData[mockData.currentQuestion]?.question,
      answer: true,
      resultMode: true,
      testMode: false,
      soundChecked: () => {},
      stopRecording: true,
      nextQuestion: () => {},
    };
    val = mockData.questionData[mockData.currentQuestion];
    console.log(mockData, mockData.currentQuestion);
  }
  let title = "";
  let ques_title = "";
  if (mockData.questionData.length > 0) {
    ques_title = mockData.questionData[mockData.currentQuestion]?.q_title;
  }
  switch (mockData.type) {
    case 1:
      que = <ReadAloud {...props} />;
      title =
        "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
      break;
    case 2:
      que = <RepeatSentece {...props} />;
      title =
        "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
      break;
    case 3:
      que = <DescribeImage {...props} />;
      title =
        "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
      break;
    case 4:
      que = <RetellLec {...props} />;
      title =
        "Listen to and read a description of a situation. You will have 20 seconds to think about your answer. Then you will hear a beep. You will have 40 seconds to answer the question. Please answer as completely as you can.";
      break;
    case 5:
      que = <ShortQue {...props} />;
      title =
        "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
      break;
    case 6:
      que = <Summary {...props} />;
      title =
        "Read the passage below. Summarize the passage using between 25 and 50 words. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.";
      break;
    case 7:
      que = <Essay {...props} />;
      title =
        "Read a description of a situation. Then write an email about the situation. You will have 9 minutes. You should aim to write at least 100 words.";
      break;
    case 8:
      topbar = true;
      que = <SingleAnswer {...props} />;
      title =
        "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
      break;
    case 9:
      topbar = true;
      que = <MultipleAnswer {...props} />;
      title =
        "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
      break;
    case 10:
      que = <Reorder {...props} />;
      title =
        "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
      break;
    case 11:
      topbar = true;
      que = <ReadingBlank {...props} />;
      title =
        "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
      break;
    case 12:
      topbar = true;
      que = <WrittingBlank {...props} />;
      title =
        "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
      break;
    case 13:
      que = <Summer {...props} />;
      title =
        "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 20-30 words. You have 8 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
      break;
    case 14:
      topbar = true;
      que = <McqSingle {...props} />;
      title =
        "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
      break;
    case 15:
      topbar = true;
      que = <McqMulti {...props} />;
      title =
        "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
      break;
    case 16:
      topbar = true;
      que = <ListeningBlank {...props} />;
      title =
        "You will hear a recording. Type the missing words in each blank.";
      break;
    case 17:
      topbar = true;
      que = <CorrectSummary {...props} />;
      title =
        "You will hear a recording. Click on the paragraph that best relates to the recording.";
      break;
    case 18:
      topbar = true;
      que = <MissingWord {...props} />;
      title =
        "You will hear a recording about " +
        ques_title +
        ". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
      break;
    case 19:
      topbar = true;
      que = <Highlight {...props} />;
      title =
        "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
      break;
    case 20:
      que = <Dictation {...props} />;
      title =
        "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
      break;
    default:
    // code block
  }

  const showScore = (e, id) => {
    toggle();
  };

  const changeQuestion = (e, k) => {
    let question = mockData.questionData[k];
    setMockData((prev) => ({
      ...prev,
      result: {},
      currentQuestion: k,
      type: question.question.subcategory_id,
    }));
    displayResult(mockData);
  };

  return (
    <>
      {topbar && (
        <div className="row infoBars">
          <div className="col-sm-3 wrongAns">
            <i className="fa fa-times" aria-hidden="true"></i>{" "}
            <span>Your selected & wrong answer</span>
          </div>
          <div className="col-sm-3 correctAns">
            <i className="fa fa-check" aria-hidden="true"></i>{" "}
            <span>Your selected & correct answer</span>
          </div>
          <div className="col-sm-3 notSelected">
            <i className="fa fa-check" aria-hidden="true"></i>{" "}
            <span>Not selected & correct answer</span>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-10">
          <h4 className="mt-0">{mockData.mockTitle}</h4>
          <h6>{title}</h6>
          <Row className="unselectable">
            {!mockData.pending && mockData.questionData && que}
            {[13, 19].includes(mockData.type) && (
              <div>
                <h3>Audio Script</h3>
                <h4>{ReactHtmlParser(val.question.audio_script)}</h4>
              </div>
            )}
          </Row>

          <Question
            val={val}
            mockData={mockData}
            que={que}
            showScore={showScore}
          />
        </div>
        <div className="col-sm-2 speakingBar p-0">
          <h4>
            <i className="" aria-hidden="true"></i>
          </h4>
          <div className="topArrow"></div>
          <PaginationBtns
            mockData={mockData}
            prevButton={prevButton}
            nextButton={nextButton}
            changeQuestion={changeQuestion}
          />
          <div className="quesStatus"></div>
          <div className="bottomBtn text-center"></div>
        </div>
      </div>
    </>
  );
};

export default ResultContainer;
