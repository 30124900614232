import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import AOS from 'aos';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import { Redirect } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class Speaking extends React.Component {

    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false,
            subdomain:false,
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        let subdomain = window.location.hostname.split('.').shift();
        if (subdomain !== 'www') {
          this.setState({subdomain:true});
        }
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 10000);
        }
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: '/submit-query',
            data: bodyFormData
          })
        }catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);
        if (res.status === 200) {
          store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          this.setState({indexModal:false});       
        }else if (res.response.status === 422) {
          this.setState({error:res.response,process:false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag:str});
        }
    
    
  }
 render(){  
    if(this.state.subdomain){
      return (<Redirect to={`auth/login`}/>);    
    }   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>20task, Empowering your Institute</title>
        <meta name="twitter:title" content="20task, Empowering your Institute" />
        <meta property="og:title" content="20task, Empowering your Institute" />
        <meta name="description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />      
        
        <meta name="twitter:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />
        <meta property="og:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />      
    </Helmet>
    <section class="header-with-banner">
      <Header active="home" />
    </section>
    <main class="main-content">
      <div class="space-after-nav"></div>
      <section class="inner-page-first-section listening-page-first-section inner-page-banner listening-page-banner py-80">
        <div class="container">
          <div class="row middle_block">
            <div class="col-md-6 col-lg-5 d-lg-flex align-items-center mobile_center_desktop_left_align">
                <div class="content">
                    <h1 class="title-h1">AI-Driven Scored PTE Mock Test & PTE Practice Test</h1>
                    <p>Prepare with scored PTE mock test and section-wise practice test to achieve your desire score with instant result. Our PTE practice test helps you to target the individual section to overcome your weakness.</p>
                  <a class="red-button btn" href="/contact">Try Our Software for Your Institute</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-7">
            </div>
          </div>
        </div>
      </section>
      <section class="inner-page-second-section py-60" id="listening-page-second-section">
      <div class="container">
        <div class="row middle_block">
          <div class="col-md-12 col-lg-12 d-lg-flex align-items-center mobile_center_desktop_left_align">
              <div class="content">
                  <p>The speaking zone uses a microphone. Students must be in a quiet room with no background noise. Since your students will be practising from home, please advise them to ask people in the surroundings of their study schedule to have the least disturbance.</p>
                  <p>The AI algorithm is just like the PTE exam. Any minute noise or distortion can affect the evaluation process of their responses, impacting their scores. The Portal is capable of assessing your students and scoring cent per cent like the PTE. So, it is important that they use a microphone with a similar standard as used for the real exam.</p>
                  <p>The speech recognition tool considers fluency, content and pronunciation. It also checks pauses and emphasis in the responses with a robust speech processing algorithm. You get an accurate analysis within a few seconds. The analysis is in-depth and helps your students in improving different aspects of speaking English. The purpose of the tool is to assess your students and compare their response with a native speaker.</p>
                  <p>Students can hear their response and reattempt a question. They can submit the response once they are satisfied with their performance. They can attempt any question randomly. The system keeps a record of which questions have they attempted, so they sort the unattempted questions and avoid investing time in doing the same questions repeatedly.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section class="inner-page-third-section py-60 display-n twbubble-bg twbubble-bg" id="listening-page-third-section">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">20task.com Grooms your Students at</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card1">
              <div class="skills-card-inner"  id="skills-card1">
                <h2 class="skills-card-inner-h2">Intonation</h2>
                <p class="skills-card-inner-p">You learn to speak properly with correct intonation and emphasis on the syllables.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card2">
              <div class="skills-card-inner" id="skills-card2">
                <h2 class="skills-card-inner-h2">Comprehension</h2>
                <p class="skills-card-inner-p">You start organising the ideas and forming a better context in the subject.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card3">
              <div class="skills-card-inner" id="skills-card3">
                <h2 class="skills-card-inner-h2">Clarity</h2>
                <p class="skills-card-inner-p">You start using proper pronunciation to talk and converse with a native speaker.</p>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card4">
              <div class="skills-card-inner" id="skills-card4">
                <h2 class="skills-card-inner-h2">Fluency</h2>
                <p class="skills-card-inner-p">You start speaking with proper pitch and fluency wherever needed.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card5">
              <div class="skills-card-inner" id="skills-card5">
                <h2 class="skills-card-inner-h2">Structure</h2>
                <p class="skills-card-inner-p">You learn to organise the ideas and forming a story depicting the proper message.</p>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </section>

    <section class="inner-page-fourth-section speakingTabs py-60">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">20task.com Hones in All Question Types</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
        <div class="row tabRow">
          <div class="col-md-3 tabRowcol tabLinkRowcol">
            <div class="ed_tab">
              <div class="row">
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow active show" href="tab1" id="edDefaultOpen">Read Aloud</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow" href="tab2">Repeat Sentence</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab3">Describe Image</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab4">Re-tell Lecture</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab5">Select Missing Word</button></div>
              </div>
            </div>
          </div>
          <div class="col-md-9 tabRowcol tabContentRowcol"> 
            <div class="row">
              <div class="col-md-12">
                <div id="tab1" class="container ed_tabcontent tab-pane fade shadow rounded bg-white show active p-0">
                  <h3 class="firsth3">Read Aloud</h3>
                  <p>You read the given text loudly with proper pronunciation, fluency, speed, emphasis and pauses.</p>
                </div>

                <div id="tab2" class="container ed_tabcontent tab-pane shadow rounded bg-white  p-0">
                  <h3>Repeat Sentence</h3>
                  <p>You listen to audio and speak in the exact manner with the same pronunciation and fluency.</p>
                </div>

                <div id="tab3" class="container ed_tabcontent tab-pane shadow rounded bg-white  p-0">
                  <h3>Describe Image</h3>
                  <p>You see a picture and describe in your words what you see using the microphone.</p>
                </div>

                <div id="tab4" class="container ed_tabcontent tab-pane shadow rounded bg-white  p-0">
                  <h3>Re-tell Lecture</h3>
                  <p>You have to summarise the lecture in your own words based on what you hear.</p>
                </div>

                <div id="tab5" class="container ed_tabcontent tab-pane shadow rounded bg-white  p-0">
                  <h3>Answer Short Question</h3>
                  <p>You answer short questions quickly in one or more words with clarity in speech.</p>
                </div>

              </div>
              <div class="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>  
    </section>

    <section class="inner-page-fifth-section listening-page-fifth-section py-60 twbubble-bg twbubble-bg">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">How 20task.com Helps Your Student Perfect Their Score?</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">Every PTE aspirant has some goal in their mind. 20task.com is a curation of several questions by the leading experts. You get a brilliant UI where each section is divided strategically, so students quickly find what they are looking for. The Portal Is designed to power your students’ preparation with new-age Artificial Intelligence. Built on PTE standard algorithms. The technology is quick. Within just a few seconds, evaluates your students’ response and gives the corrections, imitating a human-like response, but only quicker and more accurately. The 95% algorithm adoption from the PTE exam ensures that your student gets just 5% more or less marks and score almost as expected.</p>
          <p class="sec-sub-title">20task.com pumps up your students’ confidence, and they get closer to achieving their desired score.</p>
          <h3 class="sec-sub-title">20task.com</h3>
        </div>
      </div>
      <div class="container-fluid tw-features-container-fluid">
        <div class="row text-center">
          <div class="col-md-12">
            <div class="inner-div">
              <img src="/images/20task/images/20-task-features-05.png" class="twtask-fluid-container-desktop-image-sec" height="534px" width="1920px" alt="How 20task.com Helps"/>
              <img src="/images/20task/images/20-task-features-mobile.png" class="twtask-fluid-container-mobile-image-sec" height="923px" width="742px" alt="How 20task.com Helps"/>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12 text-center cta-row">
                <a href="#" class="blue-button">Crack PTE with 20task.com</a>
          </div>
        </div>
      </div>
    </section>

    <section class="inner-page-sixth-section listening-page-sixth-section process-sec py-80 hide-me hidden">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Results We Applaud and Results That Applaud Us</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">20task.com provides the PTE aspirants the opportunity to get minute details about the PTE test before the exam day. Practicing with pte full mock test with answers are more of the process of tuning oneself before the PTE academic exam. PTE practice test helps to rate your performance and get the scorecard instantly. PTE test consists of four sections having individual question type covering in it. All the exam stimulation is added in our online PTE mock test and PTE practice test to give you an edge while preparing for the PTE test.</p>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="process-box box_process_top">
              <div class="icon text-center">
                <img src="/images/20task/images/20task-icon3-07-06-21.png" width="67" height="67" alt="Reading" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Reading</h3>
                <p class="description">This section assesses reading and comprehension ability. Information is given in English. Students have various types of questions like fill-in blanks, multiple-choice questions, paragraph reordering, and others.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Reading <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box box_process_top">
              <div class="icon text-center">
                <img src="/images/20task/images/20-task-icon2-07-06-21.png" width="67" height="67" alt="Writing" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Writing</h3>
                <p class="description">In this section, students are given questions like summarising texts and writing an essay. They can use text editing functions such as cut, copy and paste to save some time. They need to maintain good speed and avoid typos.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Writing <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box">
              <div class="icon text-center">
                <img src="/images/20task/images/20taskicon1-07-06-21.png" width="67" height="67" alt="Speaking" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Speaking</h3>
                <p class="description">Students need a good quality microphone for this section. They have to Read Aloud, Repeat Sentences, Describe Images, Re-tell Lectures and Answer Short Questions. Their pronunciation and diction are tested.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Speaking <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box">
              <div class="icon text-center">
                <img src="/images/20task/images/20taskicon4-07-06-21.png" width="67" height="67" alt="Listening" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Listening</h3>
                <p class="description">This section tests your students’ hearing and understanding abilities. Audio Clips with English dialogues or monologues are played. They have to answer the corresponding questions correctly by selecting from the given options.</p>
                <a href="#" class="link_btn process-link-btn" > Improve Listening <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="inner-page-seventh-section listening-page-seventh-section testimonial-section py-60 inner-page-fifth-section" id="testimonialSection">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Results We Applaud and Results That Applaud Us</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
      </div>
    </section>
    <div class="inner-page-seventh-section-inner listening-page-seventh-section-inner gtco-testimonials">
      <div class="owl-carousel owl-carousel1 owl-theme">
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>An amazing portal that has taken the stress out of looking after every student. I can easily know their scores from the dashboard.<span class="twtask-right-quote">”</span></p>
            </div>
          </div>
        </div>
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>The portal is efficient, smooth, simple to use and shows verified results.<span class="twtask-right-quote">”</span> </p>
            </div>
          </div>
        </div>
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>I am glad I found this PTE Portal. Using this, it feels like I am running an online institute as the software provides study material, conducts mock tests and does everything for PTE preparation.<span class="twtask-right-quote">”</span> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="inner-page-eighth-section listening-page-eighth-section faq-section py-80 twbubble-bg twbubble-bg">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Frequently Asked Questions</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div class="row">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Question #1
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Question #2
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Question #3
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="horizontalCta py-80">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center horizontalCta-heading">
            <h2>Take Scored PTE Mock Test for Free</h2>
            <a href="#" class="red-button btn">Sign Up for FREE!</a>
          </div>
        </div>
      </div>
    </section>
    
    
    </main>  
  <Footer />
      
    </React.Fragment>);
    }
}

export default Speaking;