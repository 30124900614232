export const displayResult = (mockData) => {
  let answerEle;
  console.log(mockData)
  let q_data = mockData.questionData[mockData.currentQuestion];
  let selected_arr = [];
  let correct_arr = [];

  switch (q_data?.type) {
    case 11:
      if (q_data.answer) {
        q_data.answer.split(",").map((v, k) => {
          if (v.trim() === q_data.correct.split(",")[k].trim()) {
            document.querySelectorAll(".selected-option")[k].innerHTML =
              '<span class="right-blank">' +
              q_data.answer.split(",")[k] +
              '&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
          } else {
            if (document.querySelectorAll(".selected-option").length > k) {
              document.querySelectorAll(".selected-option")[k].innerHTML =
                '<span class="wrong-blank">' +
                q_data.answer.split(",")[k] +
                '&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            }
          }
        });
      } else {
        q_data.correct.split(",").map((v, k) => {
          document.querySelectorAll(".selected-option")[k].innerHTML =
            '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
        });
      }
      break;
    case 10:
      let elemArr = document.querySelectorAll(".list-group-item1");
      for (var i = 0; i < elemArr.length; i++) {
        elemArr[i].classList.add("cross");
      }

      if (q_data.answer) {
        let correct = q_data.correct.split(",");
        let answer = q_data.answer.split(",");
        for (var i = 0; i < answer.length - 1; i++) {
          for (var j = 0; j < correct.length - 1; j++) {
            if (correct.length > i + 1) {
              if (
                answer[i] + "-" + answer[i + 1] ==
                correct[j] + "-" + correct[j + 1]
              ) {
                elemArr[i].classList.remove("cross");
              }
            }
          }
        }
      }
      break;
    case 16:
      if (q_data.answer === "-") {
        let allInput = document.querySelectorAll(".selected-option");
        for (var i = 0; i < allInput.length; i++) {
          allInput[i].innerHTML =
            '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
        }
      } else {
        q_data.answer.split(",").map((v, k) => {
          if (v.trim() === q_data.correct.split(",")[k].trim()) {
            document.querySelectorAll(".selected-option")[k].innerHTML =
              '<span class="right-blank">' +
              q_data.answer.split(",")[k] +
              '&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
          } else {
            if (document.querySelectorAll(".selected-option").length > k) {
              document.querySelectorAll(".selected-option")[k].innerHTML =
                '<span class="wrong-blank">' +
                q_data.answer.split(",")[k] +
                '&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            }
          }
        });
      }

      break;
    case 12:
      if (q_data.answer) {
        q_data.correct.split(",").map((v, k) => {
          document.querySelectorAll(".selected-option")[k].innerHTML =
            '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
        });
        q_data.answer.split(",").map((v, k) => {
          if (v.trim() === q_data.correct.split(",")[k].trim()) {
            document.querySelectorAll(".selected-option")[k].innerHTML =
              '<span class="right-blank">' +
              q_data.answer.split(",")[k] +
              '&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
          } else {
            if (document.querySelectorAll(".selected-option").length > k) {
              document.querySelectorAll(".selected-option")[k].innerHTML =
                '<span class="wrong-blank">' +
                q_data.answer.split(",")[k] +
                '&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            }
          }
        });
      } else {
        q_data.correct.split(",").map((v, k) => {
          document.querySelectorAll(".selected-option")[k].innerHTML =
            '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
        });
      }

      break;
    case 15:
      changeColorReset();

      correct_arr = [];
      if (q_data.correct) {
        correct_arr = q_data.correct.split(",").map((item) => item.trim());
      }
      if (q_data.answer) {
        selected_arr = q_data.answer.split(",").map((item) => item.trim());
        selected_arr.map((k) => {
          if (correct_arr.includes(k)) {
            document.querySelector(
              'input[value="' + k + '"]'
            ).parentElement.parentElement.parentElement.style =
              "background:green";
          } else {
            document.querySelector(
              'input[value="' + k + '"]'
            ).parentElement.parentElement.parentElement.style =
              "background:red";
          }
        });
      }
      let result = correct_arr.filter((x) => !selected_arr.includes(x));
      result.map((k) => {
        document.querySelector(
          'input[value="' + k + '"]'
        ).parentElement.parentElement.parentElement.style = "background:blue";
      });
      if (q_data.correct) {
        //document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";
      } else {
        let correct = q_data.question.option.filter((p) => {
          if (p && p.correct === 1) return p;
        });
        correct.map((p) => {
          document.querySelector(
            'input[value="' + p.id + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        });
      }

      break;
    case 18:
      changeColorReset();
      answerEle = document.querySelector(
        'input[value="' + q_data.answer + '"]'
      );

      if (answerEle !== null) {
        if (
          q_data.answer !== null &&
          q_data.answer !== "" &&
          q_data.correct.trim() === q_data.answer.trim()
        ) {
          document.querySelector(
            'input[value="' + q_data.answer + '"]'
          ).parentElement.parentElement.parentElement.style =
            "background:green";
        } else {
          if (q_data.answer != null && q_data.answer !== "") {
            document.querySelector(
              'input[value="' + q_data.answer + '"]'
            ).parentElement.parentElement.parentElement.style =
              "background:red";
          }
          document.querySelector(
            'input[value="' + q_data.correct + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        }
      }
      break;
    case 17:
      changeColorReset();
      answerEle = document.querySelector(
        'input[value="' + q_data.answer + '"]'
      );

      if (answerEle !== null) {
        if (
          q_data.answer !== null &&
          q_data.answer !== "" &&
          q_data.correct.trim() === q_data.answer.trim()
        ) {
          document.querySelector(
            'input[value="' + q_data.answer + '"]'
          ).parentElement.parentElement.parentElement.style =
            "background:green";
        } else {
          if (q_data.answer !== null && q_data.answer !== "") {
            document.querySelector(
              'input[value="' + q_data.answer + '"]'
            ).parentElement.parentElement.parentElement.style =
              "background:red";
          }
          document.querySelector(
            'input[value="' + q_data.correct + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        }
      }
      break;
    case 14:
      changeColorReset();
      if (q_data.answer && q_data.correct.trim() === q_data.answer.trim()) {
        document.querySelector(
          'input[value="' + q_data.answer + '"]'
        ).parentElement.parentElement.parentElement.style = "background:green";
      } else {
        if (q_data.answer) {
          document.querySelector(
            'input[value="' + q_data.answer + '"]'
          ).parentElement.parentElement.parentElement.style = "background:red";
        }
        if (q_data.correct) {
          document.querySelector(
            'input[value="' + q_data.correct + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        } else {
          let correct = q_data.question.option.filter((p) => {
            if (p && p.correct === 1) return p;
          });
          document.querySelector(
            'input[value="' + correct[0].id + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        }
      }
      break;
    case 8:
      changeColorReset();
      if (
        q_data.answer &&
        q_data.answer != null &&
        q_data.correct.trim() === q_data.answer.trim()
      ) {
        document.querySelector(
          'input[value="' + q_data.answer + '"]'
        ).parentElement.parentElement.parentElement.style = "background:green";
      } else {
        if (q_data.answer && q_data.answer != null) {
          document.querySelector(
            'input[value="' + q_data.answer + '"]'
          ).parentElement.parentElement.parentElement.style = "background:red";
        }
        if (q_data.correct) {
          document.querySelector(
            'input[value="' + q_data.correct + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        } else {
          let correct = q_data.question.option.filter((p) => {
            if (p && p.correct === 1) return p;
          });
          document.querySelector(
            'input[value="' + correct[0].id + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        }
      }
      break;
    case 9:
      changeColorReset();
      correct_arr = [];
      if (q_data.correct) {
        correct_arr = q_data.correct.split(",").map((item) => item.trim());
      }
      if (q_data.answer) {
        selected_arr = q_data.answer.split(",").map((item) => item.trim());
        selected_arr.map((k) => {
          console.error(k);
          if (correct_arr.includes(k)) {
            if (document.querySelector('input[value="' + k + '"]')) {
              document.querySelector(
                'input[value="' + k + '"]'
              ).parentElement.parentElement.parentElement.style =
                "background:green";
            }
          } else {
            if (document.querySelector('input[value="' + k + '"]')) {
              document.querySelector(
                'input[value="' + k + '"]'
              ).parentElement.parentElement.parentElement.style =
                "background:red";
            }
          }
        });
      }
      let result1 = correct_arr.filter((x) => !selected_arr.includes(x));
      result1.map((k) => {
        if (document.querySelector('input[value="' + k + '"]')) {
          document.querySelector(
            'input[value="' + k + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        }
      });
      if (!q_data.correct) {
        let correct = q_data.question.option.filter((p) => {
          if (p && p.correct === 1) return p;
        });
        correct.map((p) => {
          document.querySelector(
            'input[value="' + p.id + '"]'
          ).parentElement.parentElement.parentElement.style = "background:blue";
        });
      }

      break;
    case 19:
      if (mockData.questionData.length > 0) {
        document.querySelector(".result-correct").innerHTML =
          mockData.questionData[mockData.currentQuestion].html;
      }

      let elem = document.querySelectorAll(".result-correct span");
      for (var i = 0; i < elem.length; i++) {
        let hig = document.querySelectorAll(".result-correct span")[i].classList
          .value;
        let higText = document.querySelectorAll(".result-correct span")[i]
          .textContent;
        if (hig == "correct hidd") {
          document.querySelectorAll(".result-correct span")[i - 1].style =
            "background:blue;color:white";
        }
      }
      for (var i = 0; i < elem.length; i++) {
        let hig = document.querySelectorAll(".result-correct span")[i].classList
          .value;
        let higText = document.querySelectorAll(".result-correct span")[i]
          .textContent;
        let corr = "";
        if (elem.length > i + 1) {
          corr = document.querySelectorAll(".result-correct span")[i + 1]
            .classList.value;
        }
        if (hig == "highlight1") {
          if (corr == "correct hidd") {
            document.querySelectorAll(".result-correct span")[i].innerHTML =
              higText +
              '&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
            document.querySelectorAll(".result-correct span")[i].style =
              "background:green";
          } else {
            document.querySelectorAll(".result-correct span")[i].innerHTML =
              higText +
              '&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            document.querySelectorAll(".result-correct span")[i].style =
              "background:red";
          }
        }
      }

      break;
    default:
  }
};

export const changeColorReset = () => {
  let allInput = document.querySelectorAll("input");
  for (var i = 0; i < allInput.length; i++) {
    allInput[i].parentElement.parentElement.parentElement.style =
      "background:white;color:black !important;";
  }
};
