/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import {store,persistor} from 'store/index';
import API,{ base} from "api.js";
import AOS from 'aos';
class Header extends React.Component {
    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            homePage:false,
            staffFlag:false,
            isMainSite:true,
            subdomain:false,
            logoIcon:"",
        }
    }
    componentDidMount() {
        let subdomain = window.location.hostname.split('.').shift();
        const isMainSite = true;
        this.setState({isMainSite:isMainSite});
        window.scrollTo(0, 0);
        if (subdomain !== 'www') {
          this.setState({subdomain:true});
          var bodyFormData = new FormData();
          bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
          API({
            method: 'POST',
            url: 'get-logo',
            data:bodyFormData
          }).then(data => {
            if (data.data.response.data) {
              this.setState({subdomain:true});
              this.setState({logoIcon:data.data.response.data});
            }else{
              this.setState({logoIcon:"/assets/images/logo.png"});
            }
          }).catch(()=>{
            this.setState({subdomain:false});
            window.location.href = "https://www.20task.com/";
          });
        }else{
          this.setState({logoIcon:"/assets/images/logo.png"});
        }
    }

    openStaff = () =>{
        this.setState({staffFlag:!this.state.staffFlag})
    }
 render(){
	return (
    <div className="header" id="main_header">
      {this.state.subdomain ? <nav className="navbar custom_navbar navbar-expand-md navbar-dark">
        <div className="container">
          <Link to="/"><img className="main-logo" src={base+this.state.logoIcon} alt="20Task"/></Link>
          <button className="navbar-toggler" id="menu_button" type="button" data-toggle="collapse" data-target="#main_menu" aria-expanded="false" aria-label="Menu">
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="main_menu">
            <ul className="navbar-nav">
              <div className="close-icon d-md-block d-sm-block d-block d-lg-none">
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  <span className="nav-text">PTE Modules</span>
                  <i className="fa fa-angle-down" aria-hidden="false"></i>
                  </a>
                  <div className="dropdown-menu" id="dropdown-menu">
                    <Link className="dropdown-item" to="/speaking">Speaking</Link>
                    <Link className="dropdown-item" to="/writing">Writing</Link>
                    <Link className="dropdown-item" to="/reading">Reading</Link>
                    <Link className="dropdown-item" to="/listening">Listening</Link>
                  </div>
              </li> */}
              <li className={(this.props.active === "login")?"active nav-item line_button":"nav-item line_button"}>
                <Link className="nav-link button button--solid button--small blue-button" to="/auth/login">Login</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link button button--solid button--small blue-button" to="/auth/register">Sign Up Free</Link>
              </li> */}
            </ul>
        </div>
        </div>
      </nav> : <nav className="navbar custom_navbar navbar-expand-md navbar-dark">
        <div className="container">
          <Link to="/"><img className="main-logo" src={base+this.state.logoIcon} alt="20Task"/></Link>
          <button className="navbar-toggler" id="menu_button" type="button" data-toggle="collapse" data-target="#main_menu" aria-expanded="false" aria-label="Menu">
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="main_menu">
            <ul className="navbar-nav">
              <div className="close-icon d-md-block d-sm-block d-block d-lg-none">
                  <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  <span className="nav-text">PTE Modules</span>
                  <i className="fa fa-angle-down" aria-hidden="false"></i>
                  </a>
                  <div className="dropdown-menu" id="dropdown-menu">
                    <Link className="dropdown-item" to="/speaking">Speaking</Link>
                    <Link className="dropdown-item" to="/writing">Writing</Link>
                    <Link className="dropdown-item" to="/reading">Reading</Link>
                    <Link className="dropdown-item" to="/listening">Listening</Link>
                  </div>
              </li>
              <li className={(this.props.active === "login")?"active nav-item line_button":"nav-item line_button"}>
                <Link className="nav-link button button--solid button--small blue-button" to="/auth/login">Login</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link button button--solid button--small blue-button" to="/contact">Contact Us</Link>
              </li>
            </ul>
        </div>
        </div>
      </nav>}
    </div>
	);
}
}

export default Header;