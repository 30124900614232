import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import AOS from 'aos';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import { Redirect } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class Listening extends React.Component {

    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            process:false,
            subdomain:false,
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        let subdomain = window.location.hostname.split('.').shift();
        if (subdomain !== 'www') {
          this.setState({subdomain:true});
        }
        if((store.getState().token)?false:true){
            setTimeout(() => { this.setState({indexModal:true}) }, 10000);
        }
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: '/submit-query',
            data: bodyFormData
          })
        }catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);
        if (res.status === 200) {
          store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          this.setState({indexModal:false});       
        }else if (res.response.status === 422) {
          this.setState({error:res.response,process:false });
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag:str});
        }
    
    
  }
 render(){  
    if(this.state.subdomain){
      return (<Redirect to={`auth/login`}/>);    
    }   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>20task, Empowering your Institute</title>
        <meta name="twitter:title" content="20task, Empowering your Institute" />
        <meta property="og:title" content="20task, Empowering your Institute" />
        <meta name="description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />      
        
        <meta name="twitter:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />
        <meta property="og:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />      
    </Helmet>
    <section class="header-with-banner">
      <Header active="home" />
    </section>
    <main class="main-content">
      <div class="space-after-nav"></div>
      <section class="inner-page-first-section listening-page-first-section inner-page-banner listening-page-banner py-80">
      <div class="container">
        <div class="row middle_block">
          <div class="col-md-6 col-lg-5 d-lg-flex align-items-center mobile_center_desktop_left_align">
              <div class="content">
                  <h1 class="title-h1">AI-Driven Scored PTE Mock Test & PTE Practice Test</h1>
                  <p>Prepare with scored PTE mock test and section-wise practice test to achieve your desire score with instant result. Our PTE practice test helps you to target the individual section to overcome your weakness.</p>
                 <a class="red-button btn" href="/contact">Try Our Software for Your Institute</a>
              </div>
          </div>
          <div class="col-md-6 col-lg-7">
          </div>
        </div>
      </div>
    </section>
    <section class="inner-page-second-section py-60" id="listening-page-second-section">
      <div class="container">
        <div class="row middle_block">
          <div class="col-md-12 col-lg-12 d-lg-flex align-items-center mobile_center_desktop_left_align">
              <div class="content">
                  <p>The listening zone uses headphones, so please ensure using properly working headphones. For different sections, students will also read, write or select, so they should also check that their mouse and keyboard are functioning properly.</p>
                  <p>20task.com portal is entirely automated and made on similar principles as PTE. </p>
                  <p>Students listen to different audio recordings, mostly containing the speeches or monologues. The Portal quickly processes their response once they submit it for the assessment. Within just a few seconds, they receive detailed analytics. The analytics points out the mistakes and also mentions how to avoid such errors.</p>
                  <p>With such in-depth feedback, your students don’t just improve, but they also master every aspect of the language. The score you see at mock tests will match upto 95% if your students are taking the test fairly with utmost seriousness. If they consider these mock tests just like the real exam, then they will definitely get similar scores or even better in the PTE. </p>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section class="inner-page-third-section py-60 display-n twbubble-bg" id="listening-page-third-section">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">20task.com Grooms your students at</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card1">
              <div class="skills-card-inner"  id="skills-card1">
                <h2 class="skills-card-inner-h2">Recognising Indicators</h2>
                <p class="skills-card-inner-p">You start understanding the basic idea and hence summarising a speech.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card2">
              <div class="skills-card-inner" id="skills-card2">
                <h2 class="skills-card-inner-h2">Drawing Conclusions</h2>
                <p class="skills-card-inner-p">You start synthesising the data and portraying the conclusions of the speech.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card3">
              <div class="skills-card-inner" id="skills-card3">
                <h2 class="skills-card-inner-h2">Organising Data</h2>
                <p class="skills-card-inner-p">You start arranging the information you hear and ordering it in a usable form.</p>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card4">
              <div class="skills-card-inner" id="skills-card4">
                <h2 class="skills-card-inner-h2">Identifying Definitions</h2>
                <p class="skills-card-inner-p">You start recognising examples, key points and definitions in the speech</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="skills-card skills-card1" id="skills-card5">
              <div class="skills-card-inner" id="skills-card5">
                <h2 class="skills-card-inner-h2">Understanding Language</h2>
                <p class="skills-card-inner-p">You start differentiating metaphorical content with the facts or opinions.</p>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </section>

    <section class="inner-page-fourth-section listningTabs py-60">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">20task.com Hones in All Question Types</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
        <div class="row tabRow">
          <div class="col-md-3 tabRowcol tabLinkRowcol">
            <div class="ed_tab">
              <div class="row">
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow active show" href="tab1"  id="edDefaultOpen">Fill in the blanks</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow" href="tab2" >Multiple Choice</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab3">Highlight Correct Summary</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab4">Summarise Spoken Test</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab5">Select Missing Word</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab6">Write from Dictation</button></div>
                <div class="col-md-12"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab7">Highlight Incorrect Words</button></div>
              </div>
            </div>
          </div>
          <div class="col-md-9 tabRowcol tabContentRowcol"> 
            <div class="row">
              <div class="col-md-12">
                <div id="tab1" class="container ed_tabcontent tab-pane shadow rounded bg-white show active p-0">
                  <h3 class="firsth3">Fill in the blanks</h3>
                  <p>You listen to the speech and place any missing words from the recording.</p>
                </div>

                <div id="tab2" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Multiple Choice</h3>
                  <p>You listen to the recordings and select one or multiple correct options.</p>
                </div>

                <div id="tab3" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Highlight Correct Summary</h3>
                  <p>You hear the recording and select the appropriate summary of the speech.</p>
                </div>

                <div id="tab4" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Summarise Spoken Test</h3>
                  <p>You listen to the audio and write an appropriate summary of the recording.</p>
                </div>

                <div id="tab5" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Select Missing Word</h3>
                  <p>You hear the audio, and relating to the context, you choose the speaker’s motive.</p>
                </div>

                <div id="tab6" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Write from Dictation</h3>
                  <p>You listen to the recording and write the content as the speaker speaks it.</p>
                </div>

                <div id="tab7" class="container ed_tabcontent tab-pane shadow rounded bg-white p-0">
                  <h3>Highlight Incorrect Words</h3>
                  <p>You hear the speech and point out the differences between the audio and transcript.</p>
                </div>
              </div>
              <div class="col-md-12"></div>
            </div>
            <div class="row cta-row">
              <div class="col-md-12 text-center">
                <a href="#" class="blue-button">Crack PTE with 20task.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </section>

    <section class="inner-page-fifth-section listening-page-fifth-section py-60 twbubble-bg">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">How 20task.com Helps Your Student Perfect Their Score?</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">Every PTE aspirant has some goal in their mind. 20task.com is a curation of several questions by the leading experts. You get a brilliant UI where each section is divided strategically, so students quickly find what they are looking for. The Portal Is designed to power your students’ preparation with new-age Artificial Intelligence. Built on PTE standard algorithms. The technology is quick. Within just a few seconds, evaluates your students’ response and gives the corrections, imitating a human-like response, but only quicker and more accurately. The 95% algorithm adoption from the PTE exam ensures that your student gets just 5% more or less marks and score almost as expected.</p>
          <p class="sec-sub-title">20task.com pumps up your students’ confidence, and they get closer to achieving their desired score.</p>
          <h3 class="sec-sub-title">20task.com</h3>
        </div>
      </div>
      <div class="container-fluid tw-features-container-fluid">
        <div class="row text-center">
          <div class="col-md-12">
            <div class="inner-div">
              <img src="/20task/images/20-task-features-05.png" class="twtask-fluid-container-desktop-image-sec" height="534px" width="1920px" alt="How 20task.com Helps"/>
              <img src="/20task/images/20-task-features-mobile.png" class="twtask-fluid-container-mobile-image-sec" height="923px" width="742px" alt="How 20task.com Helps"/>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12 text-center cta-row">
                <a href="#" class="blue-button">Crack PTE with 20task.com</a>
          </div>
        </div>
      </div>
    </section>

    <section class="inner-page-sixth-section listening-page-sixth-section process-sec py-80 hide-me hidden">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Results We Applaud and Results That Applaud Us</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">20task.com provides the PTE aspirants the opportunity to get minute details about the PTE test before the exam day. Practicing with pte full mock test with answers are more of the process of tuning oneself before the PTE academic exam. PTE practice test helps to rate your performance and get the scorecard instantly. PTE test consists of four sections having individual question type covering in it. All the exam stimulation is added in our online PTE mock test and PTE practice test to give you an edge while preparing for the PTE test.</p>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="process-box box_process_top">
              <div class="icon text-center">
                <img src="/20task/images/20task-icon3-07-06-21.png" width="67" height="67" alt="Reading" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Reading</h3>
                <p class="description">This section assesses reading and comprehension ability. Information is given in English. Students have various types of questions like fill-in blanks, multiple-choice questions, paragraph reordering, and others.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Reading <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box box_process_top">
              <div class="icon text-center">
                <img src="/20task/images/20-task-icon2-07-06-21.png" width="67" height="67" alt="Writing" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Writing</h3>
                <p class="description">In this section, students are given questions like summarising texts and writing an essay. They can use text editing functions such as cut, copy and paste to save some time. They need to maintain good speed and avoid typos.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Writing <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box">
              <div class="icon text-center">
                <img src="/20task/images/20taskicon1-07-06-21.png" width="67" height="67" alt="Speaking" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Speaking</h3>
                <p class="description">Students need a good quality microphone for this section. They have to Read Aloud, Repeat Sentences, Describe Images, Re-tell Lectures and Answer Short Questions. Their pronunciation and diction are tested.</p>
                <a href="#" class="link_btn process-link-btn" >Improve Speaking <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="process-box">
              <div class="icon text-center">
                <img src="/20task/images/20taskicon4-07-06-21.png" width="67" height="67" alt="Listening" loading="lazy"/>
              </div>
              <div class="text text-center">
                <h3 class="title title-h3">Listening</h3>
                <p class="description">This section tests your students’ hearing and understanding abilities. Audio Clips with English dialogues or monologues are played. They have to answer the corresponding questions correctly by selecting from the given options.</p>
                <a href="#" class="link_btn process-link-btn" > Improve Listening <span class="process-arrow">&#10148;</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="inner-page-seventh-section listening-page-seventh-section testimonial-section py-60 inner-page-fifth-section" id="testimonialSection">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Results We Applaud and Results That Applaud Us</h2>
          <hr class="title-divider divider-blue"></hr>
        </div>
      </div>
    </section>
    <div class="inner-page-seventh-section-inner listening-page-seventh-section-inner gtco-testimonials">
      <div class="owl-carousel owl-carousel1 owl-theme">
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>An amazing portal that has taken the stress out of looking after every student. I can easily know their scores from the dashboard.<span class="twtask-right-quote">”</span></p>
            </div>
          </div>
        </div>
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>The portal is efficient, smooth, simple to use and shows verified results.<span class="twtask-right-quote">”</span> </p>
            </div>
          </div>
        </div>
        <div>
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text"><span class="twtask-left-quote">“</span>I am glad I found this PTE Portal. Using this, it feels like I am running an online institute as the software provides study material, conducts mock tests and does everything for PTE preparation.<span class="twtask-right-quote">”</span> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="inner-page-eighth-section listening-page-eighth-section faq-section py-80 twbubble-bg">
      <div class="container">
        <div class="sec-title text-center">
          <h2 class="title-h2">Frequently Asked Questions</h2>
          <hr class="title-divider divider-blue"></hr>
          <p class="sec-sub-title">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div class="row">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Question #1
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Question #2
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Question #3
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="horizontalCta py-80">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center horizontalCta-heading">
            <h2>Take Scored PTE Mock Test for Free</h2>
            <a href="#" class="red-button btn">Sign Up for FREE!</a>
          </div>
        </div>
      </div>
    </section>
    
    
    </main>  
  <Footer />
      
    </React.Fragment>);
    }
}

export default Listening;