import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import AOS from 'aos';
import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import { Redirect } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
class Home extends React.Component {

    constructor(props) {
        AOS.init();
        super(props);
        this.state = {
            indexModal:false,
            name:'',
            email:"",
            phone:"",
            message:"",
            error:[],
            submit:false,
            flag:false,
            lockSubmit:false,
            subdomain:false,
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
      let subdomain = window.location.hostname.split('.').shift();
      if (subdomain !== 'www') {
        this.setState({subdomain:true});
      }
      ReactGA.initialize('UA-157984080-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
      ReactPixel.init('197333187452630');
      ReactPixel.pageView();
    }

    handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({[name]:value});
    }

    submitForm = () =>{
      if (this.validator.allValid() && !this.state.lockSubmit) {
          this.setState({lockSubmit:true});
          let json = {
              "name": this.state.name,
              "institute": this.state.institute,
              "email": this.state.email,
              "phone": this.state.phone,
              "address": this.state.address,
              "city": this.state.city,
              "message": this.state.message,
          }
          API({
            method: 'POST',
            url: "post/contact",
            data:json,
          }).then(data => {
            this.setState({submit:true,lockSubmit:false,name:'',institute:'',email:'',phone:'',address:'',city:'',message:'',flag:true});
            store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.response.message}));
          }).catch(()=>{
              this.setState({lockSubmit:false});
          });
      } else {
          this.validator.showMessages();
          this.forceUpdate();
      }
  }
 render(){
    if (this.state.flag) {
      return (<Redirect to="/thank-you" />);
    } 
    if(this.state.subdomain){
      return (<Redirect to={`auth/login`}/>);    
    } 
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
      <title>Contact Us - 20 Task</title>
      <meta name="twitter:title" content="Login - 20 Task" />
      <meta property="og:title" content="Login - 20 Task" />
      <meta name="description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />      
      <meta name="twitter:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
      <meta property="og:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
    </Helmet>
    <section class="header-with-banner">
      <Header active="home" />
    </section>
    <main class="main-content">
      <div class="space-after-nav"></div>
      <section class="inner-page-first-section listening-page-first-section inner-page-banner listening-page-banner py-80">
        <div class="container">
          <div class="row middle_block">
            <div class="col-xl-12 col-lg-12 mb-5">
              <h2 class="main-heading pt-5 text-center">Contact Us</h2>
              <div class="breadcum">
                <ol class="breadcrumb primary-color text-center">
                    <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                    <li class="breadcrumb-item active">Contact Us</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="contentOuter">
			<div class="container">
				<div class="row">
					<div class="col-sm-7 loginForm">
							<div class="fieldOuter">
								<input type="text" name="name" onChange={this.handleChange} value={this.state.name} placeholder="Your name..." />
								{this.validator.message('Name', this.state.name, 'required')}
							</div>
							<div class="fieldOuter">
								<input type="text" name="institute" onChange={this.handleChange} value={this.state.institute} placeholder="Your Institute name..." />
								{this.validator.message('Institute', this.state.name, 'required')}
							</div>
							<div class="fieldOuter">
								<input type="email" onChange={this.handleChange} value={this.state.email} name="email" placeholder="Your email..." />
								{this.validator.message('Email', this.state.email, 'required|email')}
							</div>
							<div class="fieldOuter">
								<input type="number" name="phone" onChange={this.handleChange} value={this.state.phone} placeholder="Your phone number..." />
								{this.validator.message('Phone number', this.state.phone, 'required|number')}
							</div>
              <div class="fieldOuter">
								<input type="text" name="address" onChange={this.handleChange} value={this.state.address} placeholder="Your address..." />
								{this.validator.message('Address', this.state.name, 'required')}
							</div>
              <div class="fieldOuter">
								<input type="text" name="city" onChange={this.handleChange} value={this.state.city} placeholder="Your city..." />
								{this.validator.message('City', this.state.name, 'required')}
							</div>
							<div class="fieldOuter">
								<textarea onChange={this.handleChange} name="message" value={this.state.message} placeholder="Message..."></textarea>
								{this.validator.message('message', this.state.message, 'required')}
							</div>
							<div class="fieldOuter text-right">
								{!this.state.lockSubmit && <button type="submit" name="" onClick={this.submitForm} class="themeBtn btn btn-secondary" >
								Submit
																</button>}
								{this.state.lockSubmit && <button type="submit" name="" class="themeBtn btn btn-secondary" >
																	Processing...
																</button>}
							</div>
					</div>
					<div class="col-sm-5 addressArea">
            <h1>Empower your Institute today</h1>
						<h3>Ask Us Anything</h3>
            <span className='mb-5'>Feel free to contact us if you need some help, consultation or you have some other questions.</span>
						<ul>
							<li>
								<i class="fa fa-map-marker" aria-hidden="true"></i>
								<span><a style={{color:'white'}} href="https://goo.gl/maps/JQHVgKAPkBA9oZKQ7" target="_blank">Level 1, Suite 1 22 Horwood Place (10,586.39 km) 2150 Parramatta, New South Wales</a></span>
							</li>
							<li>
								<i class="fa fa-envelope-o" aria-hidden="true"></i>
								<span><a style={{color:'white'}} href="mailto:info@20task.com">info@20task.com</a></span>
							</li>
							<li>
								<ul className='double-number-class'>
                  <li>
                    <span>
                      <a style={{color:'white'}} href="tel:918360986507">
                        <img src="/images/20task/images/IN.png"/>&nbsp;&nbsp;&nbsp;+918360986507
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <a style={{color:'white'}} href="tel:918360829427">
                        <img src="/images/20task/images/IN.png"/>&nbsp;&nbsp;&nbsp;+918360829427
                      </a>
                    </span>
                  </li>
                </ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
    </main>
    <Footer />
    </React.Fragment>);
    }
}

export default Home;