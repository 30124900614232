import React from "react";
import {
  Button,
  Alert,
  Input
} from "reactstrap";
import { connect } from 'react-redux';
import "components/custom.css";
import Header from '../../../components/pteportal/Header';
import Footer from '../../../components/pteportal/Footer';
import API from "api.js";
import { NavLink, Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import NotificationAlert from "react-notification-alert";
import redirectTo20Task from "util/redirect-to-20task";

const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error_status: this.props.location.search === '?session-expired' ? true : false,
      error_status_message: 'Multiple Logins Detected! Please Logout from other device or mobile, or Change your Password!',
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      logoIcon:"/images/logol.png",
      isMainSite: true,
      subdomain:false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    // redirect to 20 task
    redirectTo20Task("/auth/login" + this.props.location.search);

    let subdomain = window.location.hostname.split('.').shift();
    if (subdomain !== 'www') {
      this.setState({subdomain:true});
    }

    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    
    document.body.classList.toggle("login-page");
    this.props.setToken(null);
    
    if(this.state.error_status){
      this.notify(2, this.state.error_status_message);
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }


  notify = (type,message) => {  
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }

    let options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  postLogIn = async () => {
    if (this.state.loginLock) {
      return ;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }

    this.setState({error_bag: null, loginLock: true}, async () => {
      let bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('password', this.state.password);
      bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
      
      let res = null;

      try {
        res = await API({
          method: 'POST',
          url: '/login',
          data: bodyFormData
        });
      } catch(error) {};

      if (res.status === 200) {
        const expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60);
        
        this.props.setToken(res.data.access_token);
        this.props.setUser(res.data.user);
        this.props.setLogo(res.data.image);
        
        let sub  = (res.data.user.active_subscription.length === 0)?false:true;
        this.props.setSub(sub);
        if (res.status === 200) {
          window.location.href  = "/admin/dashboard";    
        }
        
      } else if(res.response.status === 422){
        this.setState({error: res.response }, () => {
          let str = '';
          Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          this.setState({error_bag: str, loginLock: false});
        });

      } else if (res.response.status === 402) {
        
        this.setState({ error_bag: "Wrong credentials.", loginLock: false });
      } else if (res.response.status === 403) {

        this.setState({ error_bag: res.response.data.error, loginLock: false });
      }
    });
  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  render() {
    // returning empty jsx here because we will redirect from this login page to 20Task login page, remove this if you want this page again
    return <></>;
    const { token, setToken } = this.props;
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Login - 20 Task</title>
          <meta name="twitter:title" content="Login - 20 Task" />
          <meta property="og:title" content="Login - 20 Task" />
          <meta name="description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />      
          
          <meta name="twitter:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
          <meta property="og:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
        </Helmet>

        <div className="notification-div">
          <NotificationAlert ref="notificationAlert" zIndex={9999}/> 
        </div>

        <section className="header-with-banner">
          <Header active="home" />
        </section>

        <main className="main-content">
          <div className="space-after-nav"></div>
          
          <section className="inner-page-first-section listening-page-first-section inner-page-banner listening-page-banner py-80">
            <div className="container">
              <div className="row middle_block">
                <div className="col-xl-12 col-lg-12 mb-5">
                  <h2 className="main-heading pt-5 text-center">LOGIN</h2>
                  <div className="breadcum">
                    <ol className="breadcrumb primary-color text-center">
                      <li className="breadcrumb-item"><a className="white-text" href="#">Home</a></li>
                      <li className="breadcrumb-item active">Login</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="contentOuter">
            <form onSubmit={this.submitForm}>
              <div className="container">
                <div className="row">
                  <div className={this.state.subdomain ? 'col-sm-12 loginForm' : 'col-sm-7 loginForm'}>
                    {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                    {this.state.error_status && <Alert color="danger">{this.state.error_status_message}</Alert>} 
                    <div className="fieldOuter">
                      <Input 
                          placeholder="Email"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={(e) => { this.changeInput(e)} }
                        />
                        {this.validator.message('Email', this.state.email, 'required|email')}
                    </div>
                    <div className="fieldOuter">
                      <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={(e) => { this.changeInput(e)} }
                          autoComplete="off"
                        />
                        {this.validator.message('Password', this.state.password, 'required')}
                    </div>
                    <div className="rememberForgot">
                      <div className="rememberMe">
                        <input type="checkbox" name="" />
                        <span>Remember me</span>
                      </div>
                      <div className="forgotPwd">
                        <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
                      </div>
                    </div>
                    <div className="fieldOuter text-right">
                      <Button
                        className="themeBtn"
                        onClick={(e) => {this.postLogIn()}}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                  {this.state.subdomain ? '' : <div className='col-sm-5 registerLink text-center'>
                    {this.state.isMainSite &&
                      <React.Fragment>
                        <h3>Empower your Institute today</h3>
                        <p>Feel free to contact us if you need some help, consultation or you have some other questions.</p>
                        <Link to="/contact" className="themeBtn blueBtn">Contact Us</Link>
                      </React.Fragment>
                    }
                    </div>
                  }
                </div>
              </div>
              <input  type="submit" style={{display:"none"}} />
            </form>
          </section>
        </main> 
        <Footer />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
