import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Redirect} from 'react-router-dom';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import WhyLove from '../components/Frontend/WhyLove';
import Packages from '../components/Frontend/Packages';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import TestimonialVid from '../components/Frontend/VideoPTETestimonial';
class Pte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone:'',
      course:'PTE On-Campus',
      query:'',
      flag:false,
      loginLock:false,
      readMoreFlag:false
    };
    this.validator = new SimpleReactValidator();
  }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

  changeReadMore = () =>{
    this.setState({readMoreFlag:!this.state.readMoreFlag})
  }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});
      
      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "PTE Online Sydney");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {

          
      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }
      
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){  
    if (this.state.flag) {
      return (<Redirect to="/thank-you" />);
    } 
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>PTE Online Coaching | Best PTE Training Institute - 20 Task</title>
        <meta name="twitter:title" content="PTE Online Coaching | Best PTE Training Institute - 20 Task" />
        <meta property="og:title" content="PTE Online Coaching | Best PTE Training Institute - 20 Task" />
        <meta name="description" content="Looking for PTE Online Classes? 20 Task is providing a world-class PTE online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching center, contact at +61272525311." />      
        
        <meta name="twitter:description" content="Looking for PTE Online Classes? 20 Task is providing a world-class PTE online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching center, contact at +61272525311." />
        <meta property="og:description" content="Looking for PTE Online Classes? 20 Task is providing a world-class PTE online coaching to help the students to improve their scores in the Pearson Test Of English. To get demo classes from the leading PTE Coaching center, contact at +61272525311." />
    </Helmet>
    <Header />
    <div className="innerPage">
    <section class="bannerOuter">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1>Best PTE Online Coaching in Australia</h1>
            <div class="row bannerCntnt">
            <div class="col-sm-8 my-auto">
              <iframe src="https://www.youtube.com/embed/5ZQL5XdYxu8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-sm-4 bannerForm">
            <h3>Take a FREE Demo Class of Online PTE Coaching</h3>
            <form onSubmit={this.submitForm}>
              <div class="fieldOuter">
                <input
                  type="text"
                  name="name"
                  placeholder="Name*" 
                  value={this.state.name}
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('Name', this.state.name, 'required')}
              </div>
              <div class="fieldOuter">
                <input
                  type="text"
                  name="email"
                  placeholder="Email*" 
                  value={this.state.email}
                  onChange={(e) => { this.changeInput(e)} }

                />
                {this.validator.message('Email', this.state.email, 'required|email')}
              </div>
              <div class="fieldOuter">
                <input
                  type="text"
                  placeholder="Phone*"
                  name="phone"
                  value={this.state.phone}
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('Phone', this.state.phone, 'required')}
              </div>
              <div class="fieldOuter">
                <select
                  name="course"
                  value={this.state.course}
                  onChange={(e) => { this.changeInput(e)} }
                >
                  <option value="PTE Online" selected="selected">PTE Online</option>
                  <option value="PTE On-Campus3">PTE On-Campus</option>
                  <option value="NAATI CCL Online">NAATI CCL Online</option>
                  <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                  <option value="IELTS Online">IELTS Online</option>
                  <option value="IELTS On-Campus">IELTS On-Campus</option>
                </select>
              </div>
              <div class="fieldOuter">
                <input
                  type="text"
                  placeholder="Comments/Query" 
                  name="query"
                  value={this.state.query}
                  onChange={(e) => { this.changeInput(e)} }
                />
                {this.validator.message('Query', this.state.query, 'required')}
              </div>
              <div class="fieldOuter">
                {!this.state.loginLock && <button class="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                {this.state.loginLock && <button class="themeBtn" >Wait...</button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="whatispte">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 ">
        <h2 class="title">What is <span>PTE?</span></h2>
        <p>The Pearson Test of English or PTE aims at assessing the Reading, Writing, Listening, and Speaking skills of the non-native English speakers that are willing to move abroad to a nation where English is the primary language of all communications. The scores thus achieved help the test takers to get extra points towards their Permanent Residency.</p>
        {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
            {this.state.readMoreFlag && <React.Fragment>
        <h5>Can I take PTE Training Online?</h5>
        <p>If you have some issues in attending regular classes at a PTE Training Centre, then a PTE Online Course can help you. Our PTE Training Institute 20 Task offers PTE Training Online with some great benefits. This page will help you to know more about our PTE Online Coaching classes and course fees.</p>

        <h5>Why Choose PTE Online Classes?</h5>
        <p>There is something so freeing and classic in attending a PTE Online Training that a PTE Coaching Centre classroom or On-Campus program can never offer. The ease of access to attend the course anytime from anywhere. You can utilize the mealtime to study or turn your commute time into learning time with an online PTE course.</p>
        <p>People who are living far away from our Paramatta & Sydney centers can enrol for the online program and still enjoy learning from the best experts and study material by 20 Task.</p>
        <p>Students and Professionals who cannot attend On-Campus PTE Classes due to their other underlying working or educational commitments can take benefit from online PTE coaching and still work to improve their scores.</p></React.Fragment>}
        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
      </div>
    </div>
  </div>
</section>
<section class="practicePortal">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">20 Task’s <span>Practice Portal</span></h2>
        <p>The AI based online portal assists you in practicing and honing your skills in a real-time environment.</p>
        <div class="row whyloveCntnt">
          <div class="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Real Practice Question</h3>
            <p>We have curated the previous year’s questions so you can practice better and get comfortable with the actual test surrounding.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Instant Test Scores</h3>
            <p>The mock tests are assessed immediately, and you receive a detailed scorecard instantaneously.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Makes You Confident</h3>
            <p>With such a fantastic portal and instant feedback, you get all the support and motivation you need to excel.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Take Comprehensive Sectional Tests</h3>
            <p>With our sectional mock tests, you can focus on one section of the exam. Choose from either Reading, Listening, Writing, or Speaking and focus on one area at a time.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Instant generated full scorecard</h3>
            <p>A full PTE mock test is a virtual copy of the Exam. It assesses you on Pearson's test and takes approximately 3 hours to complete with your instant generated score card at the end of score report with feedback at the end.</p>
          </div>
        </div>
        <p>Let’s make your learning meaningful and successful!</p>
        <button class="themeBtn">Get Started</button>
      </div>
    </div>
  </div>
</section>
{false && <section class="featurePricing">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2 class="title"><span>Packages</span></h2>
                <p></p>
                <div class="featurePlans smallFP">
                    <div class="featurePlan">
                        <h3>Features</h3>
                        <ul>
                            <li>Price</li>
                            <li>Practice portal access</li>
                            <li>Real exam practice questions</li>
                            <li>Proven tricks and templates</li>
                            <li>Full Length Scored Mock Test with Feedback</li>
                            <li>Scored Sectional Mock Test</li>
                            <li>In-depth feedback for the full length mock test</li>
                            <li>Complete video course by Varun Dhawan</li>
                            
                            <li>Access to Live Strategy Classes</li>
                            <li>Access to Live Practice Sessions</li>
                        </ul>
                    </div>
                    <div class="featurePackage">
                        <h3>Gold Online</h3>
                        <ul>
                            <li><span class="packagePrice">$400</span>1 Month</li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li>2</li>
                            <li>8</li>
                            <li>2</li>
                            <li>1 Month</li>
                            
                            <li>1 Month</li>
                            <li>1 Month</li>
                            <li><button class="themeBtn">Enrol</button></li>
                        </ul>
                    </div>
                    <div class="featurePackage green">
                        <h3>Plus Online</h3>
                        <ul>
                            <li><span class="packagePrice">$550</span>2 Month</li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li>4</li>
                            <li>20</li>
                            <li>4</li>
                            <li>2 Month</li>
                            
                            <li>2 Month</li>
                            <li>2 Month</li>
                            <li><button class="themeBtn">Enrol</button></li>
                        </ul>
                    </div>
                    <div class="featurePackage red">
                        <h3>Pro Online</h3>
                        <ul>
                            <li><span class="packagePrice">$650</span>Unlimited</li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li><i class="fa fa-check" aria-hidden="true"></i></li>
                            <li>8</li>
                            <li>60</li>
                            <li>8</li>
                            <li>Unlimited</li>
                            
                            <li>3 Month</li>
                            <li>3 Month</li>
                            <li><button class="themeBtn">Enrol</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>}
<section class="whyLove">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">Why choose 20 Task <span>PTE Training</span></h2>
        <div class="row whyloveCntnt">
          <div class="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>Our Pearson certified faculties help you to use their expertise in improving your reading, writing, listening, and speaking sections for Online PTE.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>We continuously involve personally with every student, which enables them to unleash the potential in them and achieve the highest scores possible.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free Books</h3>
            <p>Free Templates Every student at the 20 Task gets proven templates to study and understand the best way to write an essay and leave the best impression.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Students can easily access the study material and take practice tests through our mobile app. Just in a few taps, they can get started.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>During the entire class duration, our experts can be reached out to seek any help. They will answer all your questions and help you anywhere you stuck.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>3500 + Questions to Practice</h3>
            <p>We have curated several previous question papers to help our students in practicing the common questions that are asked frequently.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our experts note the weaknesses and strengths of students during the discussion sessions to devise personalized study plans for them.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>Our costs are pocket-friendly so the students can easily pay off their fees and improve their score.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>Our mock tests provide the students with the actual experience of taking tests. Students can test their reading, listening, and writing skills.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="whyLove">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">How Does it <span>Works?</span></h2>
        <div class="row whyloveCntnt">
          <div class="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Step 1 - Strategy Class</h3>
            <p>Strategy Class In strategy class, you will learn the basics, strategies, and templates that will help you to do great in your writing section.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Step 2 - Live Practice Session</h3>
            <p>Live Practice Session Our trainers will monitor your performance, and will also give you feedback on your approach and tactics.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Step 3 - Practice Portal</h3>
            <p>Practice Portal There are many questions to practice for honing your skills on practice portal. You can practice and submit to us.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Step 4 - Weekly Mock Test</h3>
            <p>Weekly Mock Test You will have weekly tests on Sundays and will also get a full scorecard suggesting your scores, and areas of improvement.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Step 5 - Final Consultation</h3>
            <p>Final Consultation Once you complete the course, our experts will tell you how much more practice you should do before you book the exam.</p>
          </div>
        </div>
        <Link to="/auth/register" ><button class="themeBtn">Achieve Your Desired Score</button></Link>
      </div>
    </div>
  </div>
</section>
<TestimonialVid title="What Our Students Say About Our PTE Online Classes" />

<FameWallPte />
<section class="tutorialVd">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">PTE <span>Tutorial Videos</span></h2>
        <div class="videosCntnt">
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/KrFkLVq-sgw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/cDaGxk8fYpg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="videoBlk">
            <div class="videoOuter">
              <iframe width="360" height="203" src="https://www.youtube.com/embed/EERIFvmG8AI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
</div>
</React.Fragment>);
}
}

export default Pte;