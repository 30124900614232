/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import {store,persistor} from 'store/index';
class Packages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }

    componentDidMount() {
        
    }
 render(){
	return (
		<section className="featurePricing" id="packages">
            <div className="container" >
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Video Packages</h2>
                        <p></p>
                        <div className="featurePlans">
                            <div className="featurePlan">
                                <h3>Features</h3>
                                <ul>
                                    <li>Price</li>
                                    <li>Practice portal access</li>
                                    <li>Real exam practice questions</li>
                                    <li>Proven tricks and templates</li>
                                    <li>Full length mock test with score</li>
                                    <li>In-depth feedback for the full length mock test</li>
                                    <li>1-1 tutorial with a PTE expert</li>
                                    <li>Complete video course</li>
                                </ul>
                            </div>
                            
                            <div className="featurePackage">
                                <h3>Gold</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$89</span>
                                        1 Month (Renewed Every Month)
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>1</li>
                                    <li>1</li>
                                    <li><i className="fa fa-times" aria-hidden="true"></i></li>
                                    <li>1 Month</li>
                                    <li>
                                        {store.getState().token && <Link to='/sub/payment/form/8'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>
                            <div className="featurePackage green">
                                <h3>Plus</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$299</span>
                                        2 Month
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>2</li>
                                    <li>2</li>
                                    <li>1</li>
                                    <li>2 Month</li>
                                    <li>
                                        {store.getState().token && <Link to='/sub/payment/form/2'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>
                            <div className="featurePackage red">
                                <h3>Pro</h3>
                                <ul>
                                    <li>
                                        <span className="packagePrice">$349</span>
                                        3 Month
                                    </li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li><i className="fa fa-check" aria-hidden="true"></i></li>
                                    <li>3</li>
                                    <li>3</li>
                                    <li>2</li>
                                    <li>3 Month</li>
                                    <li>
                                     {store.getState().token && <Link to='/sub/payment/form/3'> <button className="themeBtn">Enrol</button></Link>}
                                        {!store.getState().token && <Link to="/auth/login"><button className="themeBtn">Enrol</button></Link>}
                                    </li>
                                </ul>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

	);
}
}

export default Packages;