import React from "react";

const PaginationBtns = ({ mockData, prevButton, nextButton, changeQuestion }) => {
  return (
    <>
      <div className="numbersList">
        {mockData.questionData &&
          mockData.questionData.map((v, k) => {
            return (
              <span
                key={k}
                onClick={(e) => changeQuestion(e, k)}
                className={
                  mockData.currentQuestion == k ? "selected-index-result" : ""
                }
              >
                {k + 1}
              </span>
            );
          })}
      </div>
      <div className="nextPrev">
        {mockData.currentQuestion !== 0 &&
          mockData.questionData.length !== 0 && (
            <a onClick={prevButton} className="prev">
              <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>{" "}
              Prev{" "}
            </a>
          )}
        {mockData.questionData.length != mockData.currentQuestion + 1 &&
          mockData.questionData.length !== 0 && (
            <a onClick={nextButton} className="next">
              Next{" "}
              <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
            </a>
          )}
      </div>
    </>
  );
};

export default PaginationBtns;
