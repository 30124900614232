import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Sitemap extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <React.Fragment>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1> Sitemap </h1>
                    <span></span><br />
                    <a href="javascript:;" class="downArrow"><img src="/images/banner-arrow.png" alt="banner-arrow" /></a>
                </div>
            </div>
        </div>
    </section>
    </div>
    <section id="pte-modules" className="mt-5">
        <div class="container">
            <div class="row">
            <div class="col-md-6">
          <section id="la_info" class="sitemap_section">

            <h2>Information</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li><a target="_blank" class="sitemap_href" href="/">Home</a></li>
                  <li><Link target="_blank" to="/video-courses">Video Course</Link></li>
                  <li><Link target="_blank" to="/admin/mock">Mock Test</Link></li>
                  <li><Link target="_blank" to="/admin/test-material">Study Material</Link></li>
                  <li><Link target="_blank" class="sitemap_href" to="/term">Terms</Link></li>
                  <li><Link target="_blank" class="sitemap_href" to="/refund">Refund Policy</Link></li>               
                  <li><Link target="_blank" to="/about">About Us</Link></li>
                  <li><Link target="_blank" to="/contact">Contact</Link></li>
                </ul>
              </div>
            </div>
          </section>
          <section id="la_courses" class="sitemap_section">

            <h2>Courses</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  
                  <li>
                     <a class="test1">PTE </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/pte-online-coaching">PTE Online Coaching</a></li>
                        <li><a target="_blank" href="/pte-coaching-classes-parramatta">PTE Parramatta</a></li>
                        <li><a target="_blank" href="/pte-coaching-sydney">PTE Sydney</a></li>
                        
                     </ul>
                  </li>
                  <li>
                     <a class="test1" >NAATI </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/naati-ccl-online-coaching">NAATI Online Coaching</a></li>
                        <li><a target="_blank" href="/naati-coaching-classes-parramatta">NAATI Parramatta</a></li>
                        <li><a target="_blank" href="/naati-ccl-coaching-sydney">NAATI Sydney</a></li>
                        
                     </ul>
                  </li>
                  <li>
                     <a class="test1" >IELTS </a>
                     <ul className="ml-5">
                        <li><a target="_blank" href="/ielts-online-coaching">IELTS Online Coaching</a></li>
                        <li><a target="_blank" href="/ielts-coaching-parramatta">IELTS Parramatta</a></li>
                        <li><a target="_blank" href="/ielts-coaching-sydney">IELTS Sydney</a></li>
                        
                     </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          
          <section id="login_register" class="sitemap_section sitemap_last_section">

            <h2>Login/Register</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li><Link target="_blank" class="sitemap_href" to="/auth/login">Login</Link></li>
                  <li><Link target="_blank" class="sitemap_href" to="auth/register">Register</Link></li>
                </ul>
              </div>
            </div>
          </section>
        </div>
          <div class="col-md-6">
            <section id="la_resources" class="sitemap_section">

            <h2>Resourses</h2>
            <div class="row">
              <div class="col-md-12">
                <ul>
                  <li><a target="_blank" class="sitemap_href" href="https://www.languageacademy.com.au/a/videos/showvideo">Tutorial Videos</a></li>
                  <li><a class="sitemap_href" href="#">Practice Material</a>
                        <ul>
                              <li>
                                 <a class="test1" href="/pte-speaking-test-practice-material">Speaking </a>
                                 <ul className="ml-5">
                                    <li><a target="_blank" href="/admin/questionList/0/1">Read Aloud</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/2">Repeat Sentence</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/3">Describe Image</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/4">Re-tell Lecture</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/5">Answer Short Questions</a></li>
                                 </ul>
                              </li>
                              <li>
                                 <a class="test2" href="/pte-writing-test-practice-material">Writing </a>
                                 <ul className="ml-5">
                                    <li><a target="_blank" href="/admin/questionList/0/6">Summarize Written Text</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/7">Essay</a></li>
                                 </ul>
                              </li>
                              <li>
                                 <a class="test3" href="/pte-reading-test-practice-material">Reading </a>
                                 <ul className="ml-5">
                                    <li><a target="_blank" href="/admin/questionList/0/8">MCQ Single Answers</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/9">MCQ Multiple Answers</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/10">Reorder Paragraph</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/11">Fill in the Blanks</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/12">Fill in the Blanks Reading &amp; Writing</a></li>
                                 </ul>
                              </li>
                              <li>
                                 <a class="test4" href="/pte-listening-test-practice-material">Listening </a>
                                 <ul className="ml-5">
                                    <li><a target="_blank" href="/admin/questionList/0/13">Summarize Spoken Text</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/14">MCQ Multiple Answers</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/15">Fill in the Blanks</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/16">Highlight Correct Summary</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/17">MCQ Single Answers</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/18">Select Missing Word</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/19">Highlight Incorrect Word</a></li>
                                    <li><a target="_blank" href="/admin/questionList/0/20">Write From Dictation</a></li>
                                 </ul>
                              </li>
                           </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          </div>
        </div>    
        </div>
      </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Sitemap;