import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import API from "api";
import { setAlert } from "action";
import { store } from "store";
import useForm from "hooks/useForm";

const ReportQuestion = ({ mockData, reportData, setReportData }) => {
  const { formData, onChangeHandler, makeFormEmpty } = useForm({
    reportText: {
      value: "",
      validationRule: (value) => !!value,
      isValid: true,
    },
  });

  const submitReport = () => {
    var bodyFormData = new FormData();
    bodyFormData.set("text", formData.reportText.value);
    bodyFormData.set(
      "question_id",
      mockData.questionData[mockData.currentQuestion].question_id
    );
    API({
      method: "POST",
      data: bodyFormData,
      url: "report/question",
    })
      .then((data) => {
        if (data.status === 200) {
          makeFormEmpty();
          store.dispatch(
            setAlert({ flag: true, type: 1, message: data.data.message })
          );
        }
      })
      .catch(() => {});
  };

  const reportModalfunc = () => {
    setReportData((prev) => ({
      ...prev,
      reportModal: !prev.reportModal,
    }));
  };

  return (
    <>
      {reportData.reportModal && (
        <Modal isOpen={reportData.reportModal} size="lg">
          <ModalBody>
            <ModalHeader>Report the problem in this question </ModalHeader>
            <span className="text-danger">
              {!formData.reportText.isValid &&
                "Please enter some message to report the question"}
            </span>
            <Input
              type="textarea"
              value={formData.reportText.value}
              name="reportText"
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={submitReport}
            >
              Submit
            </Button>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={reportModalfunc}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ReportQuestion;
