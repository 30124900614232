/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
class WhyLove extends React.Component {
constructor(props) {
    super(props);
    this.state = {
        homePage:false
    }
}
componentDidMount() {}
render(){
	return (
<section className="whyLove">
    <div className="container">
        <div className="row">
            <div className="col-sm-12 text-center">
                <h2 className="title">Why People Love <span>20 Task</span></h2>
                <p>We provide the top-rated PTE training to the candidates to make their career journey smooth</p>
                <div className="row whyloveCntnt">
                    <div className="col-sm-4">
                        <img src="images/experienced-trainer.png" alt="experienced-trainer" />
                        <h3>Experienced Trainer</h3>
                        <p>We have Pearson certified faculty members...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/star-ribben.png" alt="star-ribben" />
                        <h3>Highest Success Rate</h3>
                        <p>Students will get a sure-shot success with the high...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/book.png" alt="book" />
                        <h3>Free Books</h3>
                        <p>Our PTE / NAATI training institute provides free books...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/settings.png" alt="settings" />
                        <h3>Latest Resources</h3>
                        <p>Making use of the latest technology, we provide...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/support.png" alt="support" />
                        <h3>Online Support</h3>
                        <p>Our experts are always thereto help the students through...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/practice-questions.png" alt="practice-questions" />
                        <h3>3500 + Questions to Practice</h3>
                        <p>We give the students various exercises and practice</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/comment.png" alt="comment" />
                        <h3>Discussion Sessions</h3>
                        <p>During the discussion sessions, Our experienced team...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/affordable-fee.png" alt="affordable-fee" />
                        <h3>Affordable Fees</h3>
                        <p>We offer the best PTE and NAATI coaching classNamees...</p>
                    </div>
                    <div className="col-sm-4">
                        <img src="images/real-test.png" alt="real-test" />
                        <h3>Real Time Test Environment</h3>
                        <p>We provide several mock tests for reading, listening...</p>
                    </div>
                </div>
                <Link to = "/contact"><button className="themeBtn">Contact us</button></Link>
            </div>
        </div>
    </div>
</section>
    );
}
}
export default WhyLove;