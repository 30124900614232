import React,{Component} from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  NavLink as NavLink1,
  TabContent, TabPane, Nav, NavItem,CardText,
  InputGroupText
} from "reactstrap";
import DataTable from 'react-data-table-component';
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total:0,
      page: 1,
      search:'',
      count:10,
      mark:'all',
      attempted:'all',
      activeTab:1,
      type:this.props.match.params.id,
      prediction:1,
      all_ok: true,
      orderby: 'desc',
      complexity:'all'
    };
  }
   componentDidMount() {
    this.chromeFunc();
     this.getListApi(1)
  }

  chromeFunc = () =>{

    if (![1,2,3,4,5].includes(parseInt(this.state.type))) {
      return true;
    }
    
    let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
      if (navigator.getUserMedia === undefined) {
        this.setState({all_ok:false});
      }else{
          navigator.getUserMedia({ audio: true },

          () => {                   
            console.log('Permission Granted');
            this.setState({all_ok:isChrome}); 
          },
          () => {
            this.setState({all_ok:false});
            console.log('Permission Denied');
            
          },
        );        
      }  

  }
  changePagination = (p) =>{

    let page = this.state.page+p;
    if(page > 0 && (page*10-10) < this.state.total){
      this.setState({page:page});
      this.getListApi(page);
    }
    
  }


  getListApi = (page) => {
    API({
        method: 'GET',
        url: 'question/'+this.state.type+'?prediction='+this.state.prediction+'&page='+page+'&search='+this.state.search+'&count='+this.state.count+'&type='+this.state.activeTab+'&mark='+this.state.mark+'&attempted='+this.state.attempted+'&complexity='+this.state.complexity+'&orderby='+this.state.orderby+'&list=1&practice=true',
      }).then(data => {
        this.setState({'data':data.data.data,total:data.data.total});
        localStorage.setItem('orderby', this.state.orderby);
    }).catch(()=>{
      
    });
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value },() => {
    	if (name === "count" ) {
    		this.setState({ page : 1 },()=>{
    			this.getListApi(1);	
    		});
    		
    	}else if(name === "mark"){
        this.getListApi(1); 
      }else if(name === "attempted"){
        this.getListApi(1); 
      }else if(name === "complexity"){
        this.getListApi(1); 
      }else if(name === "orderby"){
        this.getListApi(1); 
      }
    });
  }

  clickSearch = () => {
    this.getListApi(1);
  }

  getComplexity = (comp) =>{
    let complexity = "";
    switch(comp){
      case 0:
        complexity = <span class="badge badge-primary">Easy</span>;
        break;
      case 1:
        complexity = <span class="badge badge-primary">Medium</span>;
        break;
      case 2:
        complexity = <span class="badge badge-primary">Difficult</span>;
        break;
    }
    return complexity;
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.getListApi(1);
    }
  }

  render() {
    let title = "";
    let i = this.state.page*this.state.count-this.state.count;
    switch(this.state.type) {
      case "1":
        title = "Read Aloud";
        break;
      case "2":
        title = "Repeat Sentence";
        break;
      case "3":
        title = "Describe Image";
        break;
      case "4":
        title = "Respond To Situation";
        break;
      case "5":
        title = "Answer Short Questions";
        break;
      case "6":
        title = "Summarize written text";
        break;
      case "7":
        title = "Write Email";
        break;
      case "8":
        title = "Multiple Type, Single Answer";
        break;
      case "9":
        title = "Multiple Type, Multiple Answer";
        break;
      case "10":
        title = "Reorder Paragraph";
        break;
      case "11":
        title = "Reading Fill In The Blanks";
        break;
      case "12":
        title = "Fill In The Blanks Reading And Writting ";
        break;
      case "13":
        title = "Summarize Spoken Text";
        break;
      case "14":
        title = "MCQ Single Answer";
        break;
      case "15":
        title = "MCQ Multiple Answer";
        break;
      case "16":
        title = "Listening Fill In The Blank";
        break;
      case "17":
        // title = "Highlight Correct Summary";
        break;
      case "18":
        title = "Select Missing Word";
        break;
      case "19":
        title = "Highlight Incorrect Word";
        break;
      case "20":
        title = "Write From Dictation";
        break;
      default:
        // code block
    }
    return (
      <>
      {!this.state.all_ok && <ChromeCheck />}
        {this.state.all_ok && <div className="content">
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">{title}</CardTitle>
                        </CardHeader>
                        <CardBody>
                        <Row className="multiCcontentHead">
                        <Col xs={12}>
                          <Nav className="nav-pills-primary nav-pills-icons justify-content-center mb-5"
                            pills
                            role="tablist" tabs>
                          
                          
                            <NavLink1
                              className={'active'}
                              
                            >
                              Weekly Prediction
                            </NavLink1>
                          
                          
                        </Nav>
                        </Col>
                        <Col lg={2} md={4} sm={4} className="text-center" >
                         <label>Search by Keywords</label>
                          <InputGroup style={{width: '100%'}}>
                            <Input placeholder="Search " onKeyDown={(e) => { this._handleKeyDown(e)} } name="search" autocorrect="false" spellcheck="false" onChange={(e) => { this.changeInput(e)} } value={this.state.search} />
                            <InputGroupAddon addonType="append">
                              <InputGroupText onClick={this.clickSearch} className="btn-primary"><i className="fa fa-search" ></i></InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                         <Col lg={2} md={4} sm={4} className="text-center" >
                         <label>Practice Status</label>
                          <select onChange={this.changeInput} name="attempted" className="form-control" >
                            <option value="all">Select</option>
                            <option value="1">Attempted</option>
                            <option value="0">Not Attempted</option>
                          </select>
                         </Col>
                         <Col lg={2} md={4} sm={4} className="text-center" >
                         <label>Order By</label>
                          <select onChange={this.changeInput} name="orderby" className="form-control" >
                            <option value="desc">Newest First</option>
                            <option value="asc">Oldest First</option>
                          </select>
                         </Col>
                        <Col lg={1} md={4} sm={4} className="text-center" >
                         <label>Badge</label>
                          <select onChange={this.changeInput} name="mark" className="form-control" >
                            <option value="all">Select</option>
                            <option value="marked">All Marked</option>
                            <option value="red">Red</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                          </select>
                         </Col>
                         <Col lg={1} md={4} sm={4} className="text-center" >
                         <label>Complexity</label>
                          <select onChange={this.changeInput} name="complexity" className="form-control" >
                            <option value="all">Select</option>
                            <option value="0">Easy</option>
                            <option value="1">Medium</option>
                            <option value="2">Difficult</option>
                          </select>
                         </Col>
                        <Col lg={1} md={4} sm={4} className="text-center" >
                         <label>Pagination</label>
                          <select onChange={this.changeInput} name="count" className="form-control" >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                         </Col>
                         
                         </Row>
                         <div className="tableOuter">
                          <Table>
                            <thead className="text-primary">
                              <tr>
                                
                                      <th className="">
                                        S. No
                                      </th>
                                      <th className="">
                                        {this.state.type !== '2' && this.state.type !=='5' ? 'Title' : ''}
                                      </th>
                                      <th className="">
                                        Badge
                                      </th>
                                      <th className="">
                                        Complexity
                                      </th>
                                      <th className="">
                                        Open
                                      </th>
                                    
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.map((prop, key) => {
                                let color = (prop.tag.length >0)?prop.tag[0].tag:"grey";
                                const pastTime = new Date(prop.created_at);
                                const now = new Date();
                                const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
                                const timeDiffInMs = now.getTime() - pastTime.getTime();
                                return (
                                  <tr>
                                          <td>{++i}</td>
                                          <td className="">
                                            {timeDiffInMs >= thirtyDaysInMs ? '' : <img src="/images/new-question-tag.png" className='new-question-image'/>} {this.state.type !== '2' && this.state.type !== '5' && prop.q_title}
                                          </td>
                                          <td className="">
                                            <h4><i className="fa fa-tag" style={{color:color}}></i></h4>
                                          </td>
                                          <td className="">
                                            {this.getComplexity(prop.complexity)}
                                            
                                            
                                          </td>
                                          <td className="">
                                            <NavLink
                            to={`/admin/practice/1/${this.state.type}/${prop.id}/${this.state.activeTab}/${this.state.mark}/${this.state.attempted}/${this.state.complexity}?page=${i}&search=${this.state.search}`}
                            className="nav-link"
                            activeClassName="active"
                          >
                            <i className="" />
                            <p>Open Question</p>
                          </NavLink>
                                          </td>
                                        
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                           <Button color="primary" className="bottom-btn" onClick={this.changePagination.bind(this,-1)}  size="sm"><i class="fa fa-arrow-left"></i></Button>
                           <Button color="primary" className="bottom-btn" onClick={this.changePagination.bind(this,1)}  size="sm"><i class="fa fa-arrow-right"></i></Button>
                        </CardBody>
                      </Card>
                    </Col>
                    
                  </Row>
                </div>}
      </>
    );
  }
}

export default QuestionList;
