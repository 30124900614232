/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
class FameWallNaati extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }


    componentDidMount() {
        
    }
 render(){
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1
    };
	return (
		<section className="fameWall">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Score Cards of Our <span>NAATI CCL Students</span></h2>
                        <p></p>
                        <Slider {...settings} classNameName="fameSlider regular01 slider">
                            <div>
                              <img src="images/naati/punjabi3.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="images/naati/urdu1.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="images/naati/hindi3.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="images/naati/nepali4.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="images/naati/bangla1.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="images/naati/nepali6.jpg" alt="fame-img-01" />
                            </div>
                            <div>
                              <img src="images/naati/urdu2.jpg" alt="fame-img-02" />
                            </div>
                            <div>
                              <img src="images/naati/urdu3.jpg" alt="fame-img-03" />
                            </div>
                            <div>
                              <img src="images/naati/hindi2.jpg" alt="fame-img-04" />
                            </div>
                            <div>
                              <img src="images/naati/nepali2.jpg" alt="fame-img-05" />
                            </div>
                            <div>
                              <img src="images/naati/nepali3.jpg" alt="fame-img-05" />
                            </div>
                        </Slider>
                        <Link to="/scorecard"><button className="themeBtn">Visit our Wall of Fame</button></Link>
                    </div>
                </div>
            </div>
        </section>
	);
}
}

export default FameWallNaati;