import React from 'react';
import {store,persistor} from 'store/index';
import { Link } from 'react-router-dom';
function Footer(props) {
	return (
        <React.Fragment>
        <div className="footerUpper">
            <img src="/images/footer-top-img.png" alt="footer-top-img" />
        </div>
		<footer id="pte-footer">
            <div className="container">
                {store.getState().is_main && <div className="row headerWidgets">
                                    <div className="col footerWidget">
                                        <h3>Contact Us</h3>
                                        <ul>
                                            <li>
                                                <a href="">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                    <span><a href="mailto:info@20task.com">info@20task.com</a></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                    <span><a href="tel:61426230061">+61272525311</a></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://goo.gl/maps/JQHVgKAPkBA9oZKQ7" target="_blank">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                    <span>Level 1, Suite 1 22 Horwood Place (10,586.39 km) 2150 Parramatta, New South Wales</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col footerWidget">
                                        <h3>ABOUT US</h3>
                                        <ul>
                                            <li>
                                                <Link to="/term">Terms</Link>
                                            </li>
                                            <li>
                                                <Link to="/about">Our Story</Link>
                                            </li>
                                            <li>
                                                <Link to="/refund-policies">Refund Policies</Link>
                                            </li>
                                            <li>
                                                <Link to="/sitemap">Sitemap</Link>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    <div className="col footerWidget">
                                        <h3>PTE</h3>
                                        <ul>
                                            <li>
                                                <Link to="/pte-coaching-classes-parramatta">About PTE</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-online-classes">Online classes</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-mock-test">Mock Test</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-study-material">Study Material</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col footerWidget">
                                        <h3>NAATI</h3>
                                        <ul>
                                            <li>	
                                                <Link to="/naati-coaching-classes-parramatta">NAATI Hindi Coaching</Link>	
                                            </li>	
                                            <li>	
                                                <Link to="/naati-coaching-classes-parramatta">NAATI Punjabi Coaching</Link>	
                                            </li>	
                                            <li>	
                                                <Link to="/naati-coaching-classes-parramatta">NAATI Urdu Coaching</Link>	
                                            </li>	
                                            <li>	
                                                <Link to="/naati-coaching-classes-parramatta">NAATI Nepali Coaching</Link>	
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col footerWidget">
                                        <h3>PRACTICE MATERIAL</h3>
                                        <ul>
                                            <li>
                                                <Link to="/pte-reading-test-practice-material">Reading</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-writing-test-practice-material">Writing</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-listening-test-practice-material">Listening</Link>
                                            </li>
                                            <li>
                                                <Link to="/pte-speaking-test-practice-material">Speaking</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>}
                <div className="row bottomFooter">
                    <div className="col copyright p-0">
                        <p>Copyright © 2021 LanguageAcademy. All rights reserved</p>

                    </div>
                    {store.getState().is_main && <div className="col socialIcon p-0">
                                            <a target="_blank" href="https://www.facebook.com/languageacademyaustralia/"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                            <a target="_blank" href="https://www.instagram.com/languageacademyau/"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                            <a target="_blank" href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGxYvTu-VIE8gAAAXQBcysYhyoeMLsNbqRf-PuswFI8RzAL5y60LYHKbYMYZBVifPWMn7AFIF4-pI_yN6twk7IVrKwK8kV3mABFKcSSuEZ6xhISNB9XifF45GiA8j3LNNc6bZo=&originalReferer=https://www.languageacademy.com.au/sitemap.html&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Flanguage-academy-australia"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                            <a target="_blank" href="https://www.youtube.com/channel/UC-yXI8Y7cry-D5uuNGbYbsw"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                            <a target="_blank" href="https://twitter.com/lang_acad_au"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                            <a target="_blank" href="https://www.pinterest.com.au/LanguageAcademyAustralia/"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a>
                                        </div>}
                <small>Disclaimer - The reference to the word NAATI or PTE does not create any affiliation with or endorsement,
sponsorship or false association with the National Accreditation Authority for Translators and
Interpreters Ltd (‘NAATI’) or the Pearson Tests of English (‘PTE’) and their products and services
and 20 Task is not in any way associated to or affiliated with NAATI. 20 Task is an independent coaching provider for the NAATI CCL Test and the PTE Test.
Applications must be made directly with NAATI or PTE to undertake the test.</small>
                </div>
            </div>
        </footer>
        </React.Fragment>
	);
}

export default Footer;