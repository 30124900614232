import React, { useState, useEffect } from "react";

import Sound from 'react-sound';
import { s3base } from "api.js";

export default function Beep(props) {
	const [status, setStatus] = useState(Sound.status.STOPPED);
	
	const handleBeepFinishedPlaying = () => {
		setStatus(Sound.status.STOPPED);
		props.beepFinished();
	}

	useEffect(() => {
		if (props.status === true) {
			setStatus(Sound.status.PLAYING);
		}
	}, [props.status]);

	return (
		<>
			<Sound
				url={`${s3base}/ptedata/ptemedia/beep.mp3`}
				playStatus={status}
				onFinishedPlaying={() => handleBeepFinishedPlaying()}
			/>
		</>
	);
}