import React from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from "reactstrap";
import { useLocation } from 'react-router-dom'
import {store,persistor} from 'store/index';
import { NavLink as Link,Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { setToken,setUser } from 'action/index.js';
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (tok) => dispatch(setUser(tok))
   };
};
class AdminNavbar extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      logout: false,
      staffFlag:false,
      first_name: store.getState().user.first_name,
      last_name: store.getState().user.last_name,
      country_citizenship:store.getState().user.country_citizenship,
      country_residence:store.getState().user.country_residence,
      dob: store.getState().user.dob,
      email: store.getState().user.email,
      image: store.getState().user.image,
      phone: store.getState().user.phone,
      lang: store.getState().user.lang,
      center: store.getState().user.center,
      score: store.getState().user.score,
      listening:store.getState().user.listening,
      exam_date:store.getState().user.exam_date !== 'undefined'?store.getState().user.exam_date:'',
      overall_score:(store.getState().user.speaking+store.getState().user.reading+store.getState().user.writing+store.getState().user.listening)/4,
      modal:false,
    }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    };

  closeModal = () => {
    this.setState({modal:false});
  }

   handleClickOutside(event) {
        if (this.wrapperRef.current !== null && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({staffFlag:false})
        }
    }

  openStaff = () =>{
    this.setState({staffFlag:!this.state.staffFlag})
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({staffFlag:false})
    }
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  logout = () => {
    cookie.remove('access-token', { path: '/' });
    this.setState({logout:true});
    
  }
  render() {
    if(window.location.pathname.match('profile') && this.state.modal){
      this.setState({modal:false});
    }//alert(this.state.image)
    if (!store.getState().user.institute && !window.location.pathname.match('profile') && !window.location.pathname.match('sub/subscription') && !this.state.modal && (this.state.score == null || this.state.score == 'null' || this.state.center == 'null' || this.state.center == null || !this.state.lang || !this.state.phone || !this.state.email || !this.state.country_residence || !this.state.dob || !this.state.country_citizenship )) {
      this.setState({modal:true});
    }
    if (this.state.logout) {
      this.props.setToken(null);
      this.props.setUser(null);
      return (<Redirect to="/auth/login" />);
    }
    return (
      <>
      {this.state.modal && <Modal isOpen={this.state.modal} size="md">
        <div className="modalHeader">
          <h2> Please complete your details</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={12}>
              
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Link to="/admin/profile" ><Button color="secondary" className="modalCloseBtn" >Complete Profile</Button></Link>
          
        </ModalFooter>
      </Modal>}
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <span className="d-none d-md-block">
                  
                </span>
                <span className="d-block d-md-none"></span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              
              <Nav navbar>
                <div className="col-sm-12 text-right">
                  <span ref={this.wrapperRef} onClick={(e)=>{this.openStaff()}} className={(this.state.staffFlag)?"staff staffMenu open":"staff staffMenu"}><i className="fa fa-user" aria-hidden="true"></i> <a href="javascript:;"> <samp className="caret"></samp></a>
                      <ul className="stafDropdown">
                        <li>
                          <Link to="/admin/profile">My Profile</Link>
                        </li>
                        <li>
                          <Link to="/admin/password">Change Password</Link>
                        </li>
                        <li>
                          <a href="javascript:void(0);"  onClick={this.logout} >Logout</a>
                      </li>
                        
                      </ul>
                  </span>
                </div>
                                
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
