import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import { StatusCode } from "react-http-status-code";
class About extends React.Component {
    componentDidMount() {
        
    }
 render(){   
    return (
    <StatusCode code={404}>
    <React.Fragment>
        <div class="innerPage notFound">
        
        <section class="pageContent text-center">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <img src="/images/404-img.jpg" />
                        <div class="mt-5">
                            <p>Uh oh, we can't seem to find the page you're looking for. Try going back to <a href="">home page</a> or see contact us <a href="">here</a> for more information.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    
    </React.Fragment>
    </StatusCode>);
    }
}

export default About;